import { User } from '../../../domain/models';
import { Organization } from '../../../domain/models/organization';
import { Role } from '../../../domain/models/role';

export interface IAuthState {
  currentUser: User | null;
  currentRole: Role | null;
  currentOrganization: Organization | null;
}

export const initialAuthState: IAuthState = {
  currentUser:
    sessionStorage.getItem('coreAuth') !== null
      ? JSON.parse(window.atob(sessionStorage.getItem('coreAuth') as string))
      : null,
  currentRole: null,
  currentOrganization: null
};
