import { Breadcrumbs, Container, CssBaseline, Typography } from '@mui/material';
import React, { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../data/store';
import { resetMessage } from '../../../../data/store/actions/appActions';
import { fetchCurrentOrganization, fetchMeWithRole } from '../../../../data/store/actions/authActions';
import { selectAppMessage, selectBreadcrumb, selectIsAppLoading } from '../../../../data/store/selectors/appSelectors';
import { useFirebaseApp } from '../../../../domain/context/firebase/FirebaseAppProvider';
import { useRemoteConfig } from '../../../../domain/hooks/firebase/useRemoteConfig';
import useDomTitle from '../../hooks/dom/useDomTitle';
import { ChildrenProps } from '../../props/ChildrenProps';
import AppLoader from '../feedback/AppLoader';
import AppSnackbar from '../feedback/AppSnackbar';
import AppTopNavigation from '../navigation/AppTopNavigation';
import AppLayoutWithDrawer from './AppLayoutWithDrawer';

type IGlobalPageWrapperProps = ChildrenProps;

/**
 * Main global wrapper for all routes
 */
const GlobalPageWrapper: FC<IGlobalPageWrapperProps> = ({ children }): JSX.Element => {
  const { updateTitle } = useDomTitle('Augmentya - Assistant');
  const { t } = useTranslation(['DRAWER']);
  const dispatch = useAppDispatch();

  const { organizationId } = useParams();
  const { firebaseApp } = useFirebaseApp();
  const { syncFromRemote } = useRemoteConfig();
  const message = useAppSelector(selectAppMessage);
  const breadcrumb = useAppSelector(selectBreadcrumb);
  const isAppLoading = useAppSelector(selectIsAppLoading);

  useEffect((): (() => void) => {
    dispatch(fetchMeWithRole());
    return (): void => {
      updateTitle('Augmentya');
    };
  }, []);

  useEffect((): void => {
    if (firebaseApp !== null) {
      syncFromRemote(firebaseApp);
    }
  }, [firebaseApp]);

  useEffect((): void => {
    if (organizationId) {
      dispatch(fetchCurrentOrganization(organizationId));
    }
  }, [organizationId]);

  const onCloseSnackbar = (): void => {
    dispatch(resetMessage());
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters>
        <AppTopNavigation />
        <AppLayoutWithDrawer>
          {breadcrumb && (
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
              {breadcrumb.map(
                (b, index): JSX.Element => (
                  <Typography color="inherit" key={b}>
                    {index === 0 ? b : t<string>(`${b}`)}
                  </Typography>
                )
              )}
            </Breadcrumbs>
          )}
          {children}
        </AppLayoutWithDrawer>
      </Container>
      {isAppLoading && <AppLoader />}
      <AppSnackbar message={message.text} type={message.type} onClose={onCloseSnackbar} />
    </React.Fragment>
  );
};

export default memo(GlobalPageWrapper);
