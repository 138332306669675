import { FirebaseApp } from 'firebase/app';
import { fetchConfig, getAll, getRemoteConfig } from 'firebase/remote-config';
import { useAppDispatch } from '../../../data/store';
import { setAppConfig } from '../../../data/store/actions/appActions';
import { initialAppState, RemoteAppConfig } from '../../../data/store/model/appState';

export const useRemoteConfig = (): { syncFromRemote: (firebaseApp: FirebaseApp) => void } => {
  const dispatch = useAppDispatch();

  const syncFromRemote = (firebaseApp: FirebaseApp): void => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

    fetchConfig(remoteConfig).then((): void => {
      const configs = getAll(remoteConfig);
      const mappedConfigs: RemoteAppConfig = Object.keys(configs).reduce(
        (config, current): RemoteAppConfig => ({
          ...config,
          [current]: configs[current]?.asBoolean() as boolean
        }),
        initialAppState.config
      );

      dispatch(setAppConfig(mappedConfigs));
    });
  };

  return {
    syncFromRemote
  };
};
