import { createSelector } from '@reduxjs/toolkit';
import { Project } from '../../../domain/models';
import { IProjectState } from '../model/projectState';
import { RootState } from '../store';

// For memoization look this https://react-redux.js.org/api/hooks#using-memoizing-selectors

const selectState = (state: RootState): IProjectState => state.projects;

export const selectAllProjects = createSelector([selectState], (store): Project[] =>
  [...store.all].sort((a, b): number => a.name.localeCompare(b.name))
);
