import { ApiClient } from '../../../domain/http/augmentya/apiClient';
import { ResponseApiDTO } from '../../../domain/http/augmentya/dto/response.dto';
import { projectSlice } from '../reducers/projectReducer';
import { AppDispatch, IStore } from '../store';
import { ProjectDTO } from './../../../domain/http/augmentya/dto/project.dto';
import { fromProjectDTOToProject } from './../../../domain/http/augmentya/mapper/project/fromProjectDTOToProject';
import { Project } from './../../../domain/models/project';
import { handleSimpleErrorReponse } from './utils';

export const { setAllProjects } = projectSlice.actions;

/**
 * Fetch remote list of projects of current Organization
 *
 */
export const fetchAllProjectsOfCurrentOrganization =
  () =>
  async (dispatch: AppDispatch, getState: () => IStore): Promise<void> => {
    const currentOrganization = getState().auth.currentOrganization;

    try {
      const { data } = await ApiClient.getInstance().apiClient.get<ResponseApiDTO<ProjectDTO[]>>(`/projects/`, {
        params: {
          organization: currentOrganization?.id
        },
        data: null
      });

      const mappedData = data.data.map((p): Project => fromProjectDTOToProject(p));
      dispatch(setAllProjects(mappedData));
    } catch (e: unknown) {
      handleSimpleErrorReponse(dispatch, e);
    }
  };
