import React, { FC, memo, ReactNode } from 'react';
import useHasPolicy from '../../../../domain/hooks/authorization/useHasPolicy';
import { Policy } from '../../../../domain/models/policies';
import { ChildrenProps } from '../../props/ChildrenProps';

type IHasAnyPolicyProps = {
  policies: Policy[];
  alternative?: ReactNode;
} & ChildrenProps;

const HasAnyPolicy: FC<IHasAnyPolicyProps> = ({ policies, children, alternative = <></> }): JSX.Element => {
  const { hasAnyPolicies: hasAny } = useHasPolicy();

  return <React.Fragment>{hasAny(policies) ? children : alternative}</React.Fragment>;
};

export default memo(HasAnyPolicy);
