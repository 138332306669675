import { createSelector } from '@reduxjs/toolkit';
import { Bot } from '../../../domain/models/bot/bot';
import { AppSelectValueProp } from '../../../domain/models/inputs';
import { IBotState } from '../model/botState';
import { RootState } from '../store';

// For memoization look this https://react-redux.js.org/api/hooks#using-memoizing-selectors

const selectState = (state: RootState): IBotState => state.bots;

export const selectCurrentBot = createSelector([selectState], (store): Bot | null => store.current);
export const selectCurrentBotIsLoading = createSelector([selectState], (store): boolean => store.isFirstLoadingCurrent);
export const selectCurrentBotHaveSkeleton = createSelector(
  [selectCurrentBotIsLoading, selectCurrentBot],
  (isLoading, current): boolean => isLoading && current === null
);

export const selectAllBots = createSelector([selectState], (store): Bot[] => store.all);

export const selectSortedBots = createSelector([selectAllBots], (bots): Bot[] =>
  [...bots].sort((a, b): number => a.name.localeCompare(b.name))
);

export const selectAllSelectableBots = createSelector([selectSortedBots], (bots): AppSelectValueProp[] =>
  bots.map((b): AppSelectValueProp => ({ label: b.name, value: b.id }))
);

export const selectBotIsLoading = createSelector([selectState], (store): boolean => store.isFirstLoading);

export const selectBotsHaveSkeleton = createSelector(
  [selectBotIsLoading, selectAllBots],
  (isLoading, bots): boolean => isLoading && bots.length === 0
);
