import { FC, memo } from 'react';
import { Translation } from 'react-i18next';
import './AppLoader.scss';

const AppLoader: FC = (): JSX.Element => {
  return (
    <div className="app-loader__wrapper">
      <div className="app-loader__overlay"></div>
      <div className="app-loader__spanner app-loader__show">
        <div className="app-loader__loader app-loader__show"></div>
        <Translation ns="FEEDBACK">{(t): JSX.Element => <>{t(`loading`)}...</>}</Translation>
      </div>
    </div>
  );
};

export default memo(AppLoader);
