import { MutateBot } from './../../../../models/bot/mutatebot';
import { MutateBotDTO } from './../../dto/bot/mutatebot.dto';

export const fromMutateBotToMutateBotDTO = (dto: MutateBot): MutateBotDTO => {
  return {
    name: dto.name,
    type: dto.type,
    organization: dto.organization,
    projects: dto.projects,
    description: dto.description,
    defaultLanguage: dto.defaultLanguage,
    supportedLanguages: dto.supportedLanguages,
    location: dto.location,
    timezone: dto.timezone
  };
};
