import { AxiosError, isAxiosError } from 'axios';
import { ApiClient } from '../../../domain/http/augmentya/apiClient';
import { CurrentUserDTO } from '../../../domain/http/augmentya/dto/auth.dto';
import { OrganizationDTO } from '../../../domain/http/augmentya/dto/organization.dto';
import { ResponseApiDTO } from '../../../domain/http/augmentya/dto/response.dto';
import { RoleDTO } from '../../../domain/http/augmentya/dto/role.dto';
import { fromCurrentUserDTOToUser } from '../../../domain/http/augmentya/mapper/fromCurrentUserDTOToUser';
import { fromOrganizationDTOToOrganization } from '../../../domain/http/augmentya/mapper/fromOrganizationDTOToOrganization';
import { fromRoleDTOToRole } from '../../../domain/http/augmentya/mapper/fromRoleDTOToRole';
import { authSlice } from '../reducers/authReducer';
import { AppDispatch } from './../store';
import { setAppMessage } from './appActions';

export const { setCurrentUser, setCurrentRole, setCurrentUserAndRole, setCurrentOrganization } = authSlice.actions;

export const fetchMe =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { data } = await ApiClient.getInstance().apiClient.get<ResponseApiDTO<CurrentUserDTO>>('auth/me');
    dispatch(setCurrentUser(fromCurrentUserDTOToUser(data.data)));
  };

export const fetchMeWithRole =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    const meResponse = await ApiClient.getInstance().get<ResponseApiDTO<CurrentUserDTO>>('auth/me');
    const me = fromCurrentUserDTOToUser(meResponse.data.data);

    const roleResponse = await ApiClient.getInstance().get<ResponseApiDTO<RoleDTO[]>>(`roles/`);
    const role = fromRoleDTOToRole(roleResponse.data.data.find((r: RoleDTO): boolean => r.id === me.role) as RoleDTO);

    dispatch(
      setCurrentUserAndRole({
        user: me,
        role
      })
    );
  };

export const fetchCurrentOrganization =
  (organizationId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { data } = await ApiClient.getInstance().get<ResponseApiDTO<OrganizationDTO>>(
        `/organizations/${organizationId}`
      );
      dispatch(setCurrentOrganization(fromOrganizationDTOToOrganization(data.data)));
    } catch (e: unknown) {
      if (isAxiosError(e)) {
        dispatch(
          setAppMessage({
            type: 'error',
            text: `API.${(e as AxiosError).response?.status?.toString() || 'generic'}`
          })
        );
      } else {
        dispatch(
          setAppMessage({
            type: 'error',
            text: `API.generic`
          })
        );
      }
    }
  };
