import { Typography } from '@mui/material';
import { Namespace } from 'i18next';
import { FC, memo } from 'react';
import { Translation } from 'react-i18next';

type IAppDataGridHeaderProps = {
  field: string;
  ns: Namespace;
};

const AppDataGridHeader: FC<IAppDataGridHeaderProps> = ({ field, ns }): JSX.Element => {
  return (
    <Translation ns={ns}>
      {(t): JSX.Element => <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>{t<string>(field)}</Typography>}
    </Translation>
  );
};

export default memo(AppDataGridHeader);
