/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';

export const AppTooltip = styled(
  ({ className, ...props }: Omit<TooltipProps, 'title'> & { ns?: Namespace; title: string }) => {
    const { t } = useTranslation(props.ns);
    const { title, ...propsExceptTitle } = props;

    return (
      <Tooltip
        {...propsExceptTitle}
        title={props.ns ? t<string>(title) : title}
        arrow
        classes={{ popper: className }}
      />
    );
  }
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[300]
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px 16px',
    backgroundColor: theme.palette.grey[300]
  }
}));
