import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../data/store';
import { setCurrentRoute } from '../../../data/store/actions/appActions';
import { AvailableRoute } from '../../../data/store/model/appState';
import { selectCurrentRoute } from '../../../data/store/selectors/appSelectors';

type IuseCurrentRoute = {
  currentRoute: AvailableRoute;
};

export default function useCurrentRoute(route?: AvailableRoute): IuseCurrentRoute {
  const currentRoute = useAppSelector(selectCurrentRoute);
  const dispatch = useAppDispatch();

  useEffect((): void => {
    if (route) {
      dispatch(setCurrentRoute(route));
    }
  }, []);

  return {
    currentRoute
  };
}
