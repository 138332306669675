import { useAppSelector } from '../../../data/store';
import { selectCurrentUser } from '../../../data/store/selectors/authSelectors';

type IUseAuth = {
  isLogged: () => boolean;
};

export default function useAuth(): IUseAuth {
  const currentUser = useAppSelector(selectCurrentUser);

  const isLogged = (): boolean => {
    return currentUser !== null;
  };

  return {
    isLogged
  };
}
