import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, AutocompleteProps, Chip, useTheme } from '@mui/material';
import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteOption, AutoCompleteStyledFilledInput } from './autocomplete.model';

type IAppAutoCompleteProps = Omit<AutocompleteProps<AutocompleteOption, true, true, true>, 'margin' | 'renderInput'> & {
  label?: ReactNode;
  helperText?: ReactNode;
  flatOnRight?: boolean;
  flatOnLeft?: boolean;
};

const AppAutoComplete: FC<IAppAutoCompleteProps> = (props): JSX.Element => {
  const { flatOnRight, flatOnLeft, ...inputProps } = props;
  const { t } = useTranslation(['INPUTS']);
  const theme = useTheme();

  return (
    <Autocomplete<AutocompleteOption, true, true, true>
      multiple
      freeSolo
      handleHomeEndKeys
      noOptionsText={t('no_options')}
      getOptionLabel={(option): string => {
        return typeof option === 'string' ? option : option.title;
      }}
      renderInput={(params): JSX.Element => (
        <AutoCompleteStyledFilledInput
          variant="filled"
          className={`${flatOnRight ? 'flatOnRight' : ''} ${flatOnLeft ? 'flatOnLeft' : ''}`}
          margin="dense"
          placeholder={inputProps.placeholder}
          {...params}
        />
      )}
      renderTags={(value: readonly AutocompleteOption[], getTagProps): ReactNode =>
        value.map(
          (option: AutocompleteOption, index: number): JSX.Element => (
            <Chip
              variant="filled"
              size="small"
              label={option.title}
              sx={{
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.error.light
                }
              }}
              deleteIcon={<ClearIcon />}
              {...getTagProps({ index })}
              key={option.value}
            />
          )
        )
      }
      {...inputProps}
    />
  );
};

export default memo(AppAutoComplete);
