import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';
import './AppLabel.scss';

export type IAppLabelProps = {
  label: string;
  labelCapitalize?: boolean;
  required?: boolean;
  color?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const AppLabel: React.FC<IAppLabelProps> = (props): JSX.Element => {
  const theme = useTheme();

  const {
    label,
    labelCapitalize,
    color = theme.typography.overline.color,
    sx,
    startIcon,
    endIcon,
    required = false
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
      {startIcon && <Box sx={{ mr: 1 }}>{startIcon}</Box>}
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="overline"
          sx={{ mb: 2, color: color, ...sx }}
          className={labelCapitalize !== false ? 'AppLabel--label-capitalize' : ''}>
          {label}
          {required ? ' *' : ''}
        </Typography>
      </Box>
      {endIcon && <Box sx={{ mr: 1 }}>{endIcon}</Box>}
    </Box>
  );
};

export default React.memo(AppLabel);
