import { useAppDispatch } from '../../../data/store';
import { fetchAllProjectsOfCurrentOrganization } from '../../../data/store/actions/projectActions';

type IUseLoadAllProjectsUseCase = {
  loadProjects: () => Promise<void>;
};

export default function useLoadAllProjectsUseCase(): IUseLoadAllProjectsUseCase {
  const dispatch = useAppDispatch();

  const loadProjects = async (): Promise<void> => {
    dispatch(fetchAllProjectsOfCurrentOrganization());
  };

  return {
    loadProjects
  };
}
