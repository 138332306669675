import { useAppDispatch } from '../../../data/store';
import { setAppMessage } from '../../../data/store/actions/appActions';
import { deleteMassiveBots } from '../../../data/store/actions/botActions';

type IUseDeleteMultipleBotsUseCase = {
  deleteBots: (bots: string[]) => Promise<boolean>;
};

export default function useDeleteMultipleBotsUseCase(): IUseDeleteMultipleBotsUseCase {
  const dispatch = useAppDispatch();

  const deleteBots = async (bots: string[]): Promise<boolean> => {
    const res = await dispatch(deleteMassiveBots(bots));
    if (res) {
      dispatch(
        setAppMessage({
          type: 'success',
          text: 'BOT.delete_massive_succes'
        })
      );
    }

    return res;
  };

  return {
    deleteBots
  };
}
