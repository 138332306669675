import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SimplePaletteColorOptions,
  styled,
  SxProps,
  Theme
} from '@mui/material';
import React from 'react';
import { ActionProps } from '../../props/ActionProps';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    backgroundColor: theme.palette.grey[900],
    backgroundImage: 'none',
    minWidth: '30%'
  }
}));

type IAppDialogProps = {
  title?: string;
  content: JSX.Element | React.ReactNode | string;
  open: boolean;
  actions?: React.ReactNode;
  closeButton?: boolean;
  sx?: SxProps<Theme>;
} & Pick<ActionProps, 'onCancel'>;

const AppDialog: React.FC<IAppDialogProps> = ({
  title,
  actions,
  content,
  open,
  closeButton = false,
  sx,
  onCancel
}): JSX.Element => {
  return (
    <StyledDialog
      open={open}
      onClose={onCancel}
      aria-labelledby="app-dialog-title"
      aria-describedby="app-dialog-description"
      sx={sx}>
      {title && (
        <DialogTitle id="app-dialog-title">
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {closeButton && (
              <Box>
                <IconButton
                  aria-label="close"
                  onClick={onCancel}
                  sx={{
                    color: (theme): string => (theme.palette.light as SimplePaletteColorOptions).main
                  }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </DialogTitle>
      )}
      <DialogContent>
        {typeof content === 'string' ? (
          <DialogContentText id="app-dialog-description">{content}</DialogContentText>
        ) : (
          content
        )}
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </StyledDialog>
  );
};

export default React.memo(AppDialog);
