import { createContext, useContext } from 'react';
import { IFirebaseProvider } from '../../models/firebase';
import { useFirebaseProvider } from './useFirebaseProvider';

const FirebaseContext = createContext<IFirebaseProvider>({
  firebaseApp: null
});

export const useFirebaseApp = (): IFirebaseProvider => {
  return useContext(FirebaseContext);
};

export function FirebaseProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const config = useFirebaseProvider();
  return <FirebaseContext.Provider value={config}>{children}</FirebaseContext.Provider>;
}
