/**
 * Enum for policies in app
 */
export enum Policy {
  MUTABLE_GLASS = 'MUTABLE_GLASS',
  MUTABLE_GLASS_IN_ORGANIZATION = 'MUTABLE_GLASS_IN_ORGANIZATION',
  MUTABLE_MEDIA = 'MUTABLE_MEDIA',
  MUTABLE_MEDIA_IN_ORGANIZATION = 'MUTABLE_MEDIA_IN_ORGANIZATION',
  MUTABLE_ORGANIZATION = 'MUTABLE_ORGANIZATION',
  MUTABLE_ROOM = 'MUTABLE_ROOM',
  MUTABLE_ROOM_IN_ORGANIZATION = 'MUTABLE_ROOM_IN_ORGANIZATION',
  MUTABLE_USER = 'MUTABLE_USER',
  MUTABLE_USER_IN_ORGANIZATION = 'MUTABLE_USER_IN_ORGANIZATION',
  VIEW_GLASS = 'VIEW_GLASS',
  VIEW_GLASS_IN_ORGANIZATION = 'VIEW_GLASS_IN_ORGANIZATION',
  VIEW_GLASS_ALL_IN_ORGANIZATION = 'VIEW_GLASS_ALL_IN_ORGANIZATION',
  VIEW_MEDIA = 'VIEW_MEDIA',
  VIEW_MEDIA_IN_ORGANIZATION = 'VIEW_MEDIA_IN_ORGANIZATION',
  VIEW_ORGANIZATION = 'VIEW_ORGANIZATION',
  VIEW_ORGANIZATION_IN_ORGANIZATION = 'VIEW_ORGANIZATION_IN_ORGANIZATION',
  VIEW_ROOM = 'VIEW_ROOM',
  VIEW_ROOM_ALL_IN_ORGANIZATION = 'VIEW_ROOM_ALL_IN_ORGANIZATION',
  VIEW_ROOM_IN_ORGANIZATION = 'VIEW_ROOM_IN_ORGANIZATION',
  VIEW_USER = 'VIEW_USER',
  VIEW_USER_IN_ORGANIZATION = 'VIEW_USER_IN_ORGANIZATION',
  VIEW_BOT = 'VIEW_BOT',
  VIEW_BOT_ALL_IN_ORGANIZATION = 'VIEW_BOT_ALL_IN_ORGANIZATION',
  VIEW_BOT_IN_ORGANIZATION = 'VIEW_BOT_IN_ORGANIZATION',
  MUTABLE_PROJECT = 'MUTABLE_PROJECT',
  MUTABLE_PROJECT_IN_ORGANIZATION = 'MUTABLE_PROJECT_IN_ORGANIZATION',
  MUTABLE_GROUP_IN_ORGANIZATION = 'MUTABLE_GROUP_IN_ORGANIZATION',
  MUTABLE_GROUP = 'MUTABLE_GROUP',
  MUTABLE_BOT = 'MUTABLE_BOT',
  MUTABLE_BOT_IN_ORGANIZATION = 'MUTABLE_BOT_IN_ORGANIZATION',
  MIGRATE_USER = 'MIGRATE_USER',
  MIGRATE_GLASS = 'MIGRATE_GLASS'
}

export const ADMINISTRATION_POLICIES = [
  Policy.MUTABLE_GLASS,
  Policy.MUTABLE_GLASS_IN_ORGANIZATION,
  Policy.MUTABLE_ROOM,
  Policy.MUTABLE_ORGANIZATION,
  Policy.MUTABLE_USER,
  Policy.MUTABLE_USER_IN_ORGANIZATION,
  Policy.MUTABLE_PROJECT,
  Policy.MUTABLE_PROJECT_IN_ORGANIZATION,
  Policy.MUTABLE_GROUP,
  Policy.MUTABLE_GROUP_IN_ORGANIZATION,
  Policy.MUTABLE_BOT,
  Policy.MUTABLE_BOT_IN_ORGANIZATION,
  Policy.MIGRATE_USER,
  Policy.MIGRATE_GLASS
];
