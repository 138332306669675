import { AppBar, Box, Toolbar } from '@mui/material';
import { FC, memo } from 'react';
import useAssetUrl from '../../hooks/assets/useAssetUrl';
import useCustomNavigation from '../../hooks/navigation/useCustomNavigation';
import { OptionalChildrenProps } from '../../props/OptionlChildrenProps';
import AppTopMenu from './AppTopMenu';
import AppTopUserMenu from './AppTopUserMenu';

type IAppTopNavigationProps = OptionalChildrenProps;

const AppTopNavigation: FC<IAppTopNavigationProps> = ({ children }): JSX.Element => {
  const { getAsset } = useAssetUrl();
  const { navigate } = useCustomNavigation();

  const goToLink = (link: string): void => {
    navigate(link);
  };

  return (
    <Box sx={{ flexGrow: 1 }} className="app-top_nav">
      <AppBar position="static" sx={{ zIndex: (theme): number => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ px: 2 }}>
          <Box onClick={(): void => goToLink('/dashboard')} data-testid="go-to-dashboard" className="clickable">
            <img src={getAsset('logo_augmentya.png')} alt="Augmentya Logo" />
          </Box>
          <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="center" alignItems="center">
            {children ? children : <AppTopMenu />}
          </Box>
          <AppTopUserMenu />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default memo(AppTopNavigation);
