import { ThemeProvider } from '@mui/material';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './data/store';
import { ConfigProvider } from './domain/context/config/ConfigProvider';
import { FirebaseProvider } from './domain/context/firebase/FirebaseAppProvider';
import i18n from './i18n';
import './Root.scss';
import { appTheme } from './Theme';

export default function Root(): JSX.Element {
  return (
    <React.StrictMode>
      <ThemeProvider theme={appTheme}>
        <Provider store={store}>
          <ConfigProvider>
            <FirebaseProvider>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </I18nextProvider>
            </FirebaseProvider>
          </ConfigProvider>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
}
