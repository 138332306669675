import { useEffect, useState } from 'react';
import { useConfig } from '../../../../domain/context/config/ConfigProvider';

export default function useAssetUrl(): { getAsset: (url: string) => string } {
  const { env, url: uri } = useConfig();
  const [baseUrlApp, setBaseUrlApp] = useState<string>('');

  useEffect((): void => {
    const publicPath = env !== 'production' ? uri.base : `${uri.base}${uri.projectBase}`;
    const publicPathSuffix = publicPath.endsWith('/') ? '' : '/';

    setBaseUrlApp(`${publicPath}${publicPathSuffix}`);
  }, []);

  const getAsset = (url: string): string => {
    const urlPrefix = url.startsWith('/') ? '' : '/';

    return `${baseUrlApp}assets${urlPrefix}${url}`;
  };

  return {
    getAsset
  };
}
