import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

type IDrawerIconProps = SvgIconProps;

const DrawerIcon: FC<IDrawerIconProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="-4 -8 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-980.000000, -33.000000)" fill="#95C11F" fillRule="nonzero">
          <g transform="translate(0.000000, 16.000000)">
            <g transform="translate(33.000000, 8.000000)">
              <g transform="translate(947.000000, 9.000000)">
                <g transform="translate(5.000000, 2.500000) rotate(-270.000000) translate(-5.000000, -2.500000) translate(2.500000, -2.500000)">
                  <polygon points="-1.77635684e-15 0 -1.77635684e-15 10 5 5"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default memo(DrawerIcon);
