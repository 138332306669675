import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, IconButton, Theme, useTheme } from '@mui/material';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid';
import { FC, Fragment, memo, useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Link, Location, useLocation } from 'react-router-dom';
import { Bot } from '../../../../../domain/models';
import { BotStatusThemeColor } from '../../../../../domain/models/bot/bot';
import useDeleteBotUseCase from '../../../../../domain/usecases/bot/useDeleteBotUseCase';
import useDeleteMultipleBotsUseCase from '../../../../../domain/usecases/bot/useDeleteMultipleBotsUseCase';
import { ActionProps } from '../../../../utils/props/ActionProps';
import AppDataGrid from '../../../../utils/ui/data-display/table/AppDataGrid';
import AppDataGridCellActions from '../../../../utils/ui/data-display/table/AppDataGridCellActions';
import AppDataGridCellLink from '../../../../utils/ui/data-display/table/AppDataGridCellLink';
import AppDataGridCellRegular from '../../../../utils/ui/data-display/table/AppDataGridCellRegular';
import AppDataGridHeader from '../../../../utils/ui/data-display/table/AppDataGridHeader';
import { AppTooltip } from '../../../../utils/ui/data-display/tooltip/AppTooltip';
import AppStatusLabel from '../../../../utils/ui/data-display/typography/AppStatusLabel';
import AppAlertDialog from '../../../../utils/ui/feedback/AppAlertDialog';
import SettingsVariantIcon from '../../../../utils/ui/icons/SettingsVariantIcon';
import TrashIcon from '../../../../utils/ui/icons/TrashIcon';

type CreateColumnsProps = Pick<ActionProps, 'onDelete'> & {
  theme: Theme;
  massiveDelete: boolean;
  currentRoute: Location;
  onDeleteMultipleBots: () => void;
};

const createColumns = ({
  onDelete,
  onDeleteMultipleBots,
  massiveDelete,
  currentRoute
}: CreateColumnsProps): GridColDef[] => [
  {
    field: 'name',
    type: massiveDelete ? 'actions' : 'string',
    flex: 2,
    sortable: !massiveDelete,
    renderHeader: (params: GridColumnHeaderParams): JSX.Element =>
      /** Display conditionally 'Name' header or CTA for delete massive bots */
      massiveDelete ? (
        <AppTooltip title="ACTIONS.delete_selected" ns="BOT_LIST">
          <Button
            aria-label="delete selected"
            onClick={onDeleteMultipleBots}
            color="error"
            startIcon={<RemoveCircleOutlineIcon />}>
            <Translation ns="BOT_LIST">{(t): JSX.Element => <>{t(`ACTIONS.delete_selected`)}</>}</Translation>
          </Button>
        </AppTooltip>
      ) : (
        <AppDataGridHeader ns="BOT_LIST" field={`TABLE.${params.field}`} />
      ),
    renderCell: ({ row }: GridRenderCellParams<JSX.Element, Bot>): JSX.Element => (
      <AppDataGridCellLink text={row.name} link={`bots/${row.id}`} />
    )
  },
  {
    field: 'defaultLanguage',
    type: 'string',
    flex: 1,
    sortable: !massiveDelete,
    renderHeader: (params: GridColumnHeaderParams): JSX.Element | null =>
      !massiveDelete ? <AppDataGridHeader ns="BOT_LIST" field={`TABLE.${params.field}`} /> : null,
    renderCell: ({ row }: GridRenderCellParams<JSX.Element, Bot>): JSX.Element => (
      <AppDataGridCellRegular text={row.defaultLanguage} />
    )
  },
  {
    field: 'status',
    type: 'string',
    flex: 1,
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams): JSX.Element | null =>
      !massiveDelete ? <AppDataGridHeader ns="BOT_LIST" field={`TABLE.${params.field}`} /> : null,
    renderCell: ({ row }: GridRenderCellParams<JSX.Element, Bot>): JSX.Element => (
      <AppStatusLabel ns={'BOT'} text={`STATUS.${row.status}`} status={BotStatusThemeColor[row.status]} />
    )
  },
  {
    field: 'actions',
    type: 'actions',
    flex: 1,
    hide: massiveDelete,
    sortable: false,
    headerAlign: 'right',
    align: 'right',
    renderCell: ({ row }: GridRenderCellParams<JSX.Element, Bot>): JSX.Element => (
      <AppDataGridCellActions id={row.id}>
        <AppTooltip title="ACTIONS.settings" ns="BOT_LIST">
          <IconButton
            aria-label="settings"
            sx={{ mr: 1 }}
            component={Link}
            to={`bots/${row.id}/settings`}
            state={{ previousRoute: currentRoute }}>
            <SettingsVariantIcon />
          </IconButton>
        </AppTooltip>
        <AppTooltip title="ACTIONS.delete" ns="BOT_LIST">
          <IconButton aria-label="delete" onClick={(): void => onDelete(row.id, row.name)}>
            <TrashIcon color="error" />
          </IconButton>
        </AppTooltip>
      </AppDataGridCellActions>
    ),
    renderHeader: (params: GridColumnHeaderParams): JSX.Element => (
      <AppDataGridHeader ns="BOT_LIST" field={`TABLE.${params.field}`} />
    )
  }
];

type IBotListTableProps = {
  bots: Bot[];
  isFirstLoading: boolean;
};

const BotListTable: FC<IBotListTableProps> = ({ bots, isFirstLoading }): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation(['BOT_LIST']);
  const currentRoute = useLocation();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectSingleToDelete, setSingleToDelete] = useState<{ id: string; name: string } | null>(null);
  const { deleteBots } = useDeleteMultipleBotsUseCase();
  const { deleteBot: deleteSingleBot } = useDeleteBotUseCase();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const deleteMessage = useMemo((): string => {
    if (selectSingleToDelete !== null) {
      return t('bot_delete', { name: selectSingleToDelete.name });
    }

    if (selectionModel.length > 0) {
      return t('bot_massive_delete');
    }
    return '';
  }, [selectionModel, selectSingleToDelete]);

  const deleteBot = (id: string, name?: string): void => {
    setSingleToDelete({ id, name: name as string });
    setDeleteDialogOpen(true);
  };

  const displayConfirmDialogToDeleteMultipleBots = (): void => {
    setDeleteDialogOpen(true);
  };

  const handleCancelDeleteDialog = (): void => {
    setDeleteDialogOpen(false);
    setSingleToDelete(null);
  };

  const handleConfirmDeleteDialog = (): void => {
    if (selectSingleToDelete !== null) {
      deleteSingleBot(selectSingleToDelete.id).finally((): void => {
        setDeleteDialogOpen(false);
        setSingleToDelete(null);
      });
    }

    if (selectionModel.length > 0) {
      deleteBots(selectionModel as string[])
        .then((res): void => {
          if (res) {
            setSelectionModel([]);
          }
        })
        .finally((): void => {
          setDeleteDialogOpen(false);
        });
    }
  };

  const columns = useMemo((): GridColDef[] => {
    return createColumns({
      theme,
      currentRoute,
      onDelete: deleteBot,
      onDeleteMultipleBots: displayConfirmDialogToDeleteMultipleBots,
      massiveDelete: selectionModel.length > 0
    });
  }, [deleteBot, selectionModel]);

  return (
    <Fragment>
      <AppDataGrid
        rows={bots}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }]
          }
        }}
        displaySkeleton={isFirstLoading}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel): void => {
          setSelectionModel(newSelectionModel);
        }}
      />
      <AppAlertDialog
        open={deleteDialogOpen}
        message={deleteMessage}
        onCancel={handleCancelDeleteDialog}
        onConfirm={handleConfirmDeleteDialog}
      />
    </Fragment>
  );
};

export default memo(BotListTable);
