import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

type IBaloonIconProps = SvgIconProps;

const BaloonIcon: FC<IBaloonIconProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9974 9.49822C13.4401 8.62188 13.6702 7.65061 13.6677 6.66601V6.33352C13.4893 3.08029 10.921 0.511981 7.70446 0.33453L7.3344 0.333517C6.35123 0.330952 5.37964 0.561066 4.5005 1.00512C2.35765 2.07607 1.002 4.26819 1.00107 6.66659C0.998753 7.5553 1.18645 8.43434 1.55033 9.24422L0.368616 12.7894C0.194892 13.3105 0.690718 13.8064 1.21189 13.6326L4.75704 12.4509C5.56693 12.8148 6.4461 13.0025 7.33614 13.0002C9.73306 12.9993 11.9252 11.6436 12.9974 9.49822ZM4.59025 11.1011L2.05516 11.9461L2.90019 9.411C2.9564 9.24237 2.94294 9.05828 2.8628 8.89962C2.51343 8.20793 2.33238 7.4435 2.3344 6.66859C2.33514 4.77363 3.40539 3.04301 5.09911 2.19652C5.79333 1.84588 6.55776 1.66483 7.33267 1.66685L7.66774 1.66685C10.1689 1.80585 12.1954 3.83233 12.3354 6.37024L12.3344 6.66859C12.3364 7.4435 12.1554 8.20793 11.806 8.89962C10.9582 10.5959 9.22762 11.6661 7.33415 11.6669C6.55776 11.6689 5.79333 11.4878 5.10164 11.1385C4.94298 11.0583 4.75888 11.0449 4.59025 11.1011Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default memo(BaloonIcon);
