import { Menu, MenuProps, styled } from '@mui/material';
import { FC, memo } from 'react';

const SyledMenu = styled(
  (props: MenuProps): JSX.Element => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ),
  {
    label: 'appMenu'
  }
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
)<MenuProps>(() => ({
  '& .MuiMenu-paper': {
    backgroundColor: '#292929'
  }
}));

type IAppMenuProps = MenuProps;

const AppMenu: FC<IAppMenuProps> = (props): JSX.Element => {
  return <SyledMenu {...props} />;
};

export default memo(AppMenu);
