import { FirebaseApp, initializeApp } from 'firebase/app';
import { useEffect, useState } from 'react';
import { useConfig } from '../config/ConfigProvider';
import { IFirebaseProvider } from './../../models/firebase';

export function useFirebaseProvider(): IFirebaseProvider {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
  const { firebase } = useConfig();

  useEffect((): void => {
    setFirebaseApp(initializeApp(firebase));
  }, []);

  return {
    firebaseApp
  };
}
