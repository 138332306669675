import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartialBotFlow } from '../../../domain/models/botbuilder/botflow';
import { IBotBuilderState, initialBotBuilderState } from '../model/botBuilderState';
import { BotBuilderIntent } from './../../../domain/models/botbuilder/intent';
import { BotBuilderPage } from './../../../domain/models/botbuilder/page';
import { StepFlow } from './../../../domain/models/botbuilder/stepflow';

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const botBuilderSlice = createSlice({
  name: 'botBuilder',
  initialState: initialBotBuilderState,
  reducers: {
    resetBotBuilder: (): IBotBuilderState => initialBotBuilderState,
    setBot: (state: IBotBuilderState, { payload }: PayloadAction<PartialBotFlow>): void => {
      state.bot = payload;
    },
    unSetBot: (state: IBotBuilderState): void => {
      state.bot = null;
    },
    setStartingStep: (state: IBotBuilderState, { payload }: PayloadAction<StepFlow>): void => {
      state.startStep = payload;
    },
    setSteps: (state: IBotBuilderState, { payload }: PayloadAction<{ [x: string]: StepFlow }>): void => {
      state.steps = payload;
    },
    setBotBuilderPages: (state: IBotBuilderState, { payload }: PayloadAction<BotBuilderPage[]>): void => {
      state.pages = payload;
    },
    setBotBuilderIntents: (state: IBotBuilderState, { payload }: PayloadAction<BotBuilderIntent[]>): void => {
      state.intents = payload;
    }
  }
});

export default botBuilderSlice.reducer;
