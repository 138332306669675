import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

type IAvatarIconProps = SvgIconProps;

const AvatarIcon: FC<IAvatarIconProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-944.000000, -24.000000)" fill="#7D7D7D" fillRule="nonzero">
          <g transform="translate(0.000000, 16.000000)">
            <g transform="translate(33.000000, 8.000000)">
              <g transform="translate(911.500000, 0.000000)">
                <path
                  d="M12,3.9999 C9.7911,3.9999 8.0001,5.7909 8.0001,7.9998 C8.0001,10.2096 9.7911,12 12,12 C14.2089,12 15.9999,10.2096 15.9999,8.0001 C15.9999,5.7909 14.2089,3.9999 12,3.9999 Z"
                  id="Shape"></path>
                <path
                  d="M12,0 C5.3721,0 0,5.3727 0,12.0003 C0,18.627 5.3721,24 12,24 C18.627,24 24,18.627 24,12.0003 C24,5.3727 18.627,0 12,0 Z M19.998,17.9832 C19.9893,15.783 18.2031,14.0001 15.9999,14.0001 L8.0001,14.0001 C5.7969,14.0001 4.0098,15.7821 4.002,17.9844 C2.7501,16.3146 2.0001,14.247 2.0001,12.0003 C2.0001,6.477 6.4767,1.9998 12,1.9998 C17.5224,1.9998 21.9999,6.477 21.9999,12.0003 C21.9999,14.247 21.2499,16.3146 19.998,17.9832 Z"
                  id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default memo(AvatarIcon);
