import { appSlice } from '../reducers/appReducer';

export const {
  setAppConfig,
  openDrawer,
  closeDrawer,
  resetMessage,
  setAppMessage,
  setSystemMessage,
  setBreadcrumb,
  setCurrentRoute,
  setStartLoading,
  setStopLoading
} = appSlice.actions;
