import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BotPublishedState } from '../../../../domain/models/bot/bot';
import { AppSelectValueProp } from '../../../../domain/models/inputs';
import { Policy } from '../../../../domain/models/policies';
import { ActionProps } from '../../../utils/props/ActionProps';
import HasAnyPolicy from '../../../utils/ui/auth/HasAnyPolicy';
import AppSearchInput from '../../../utils/ui/inputs/AppSearchInput';
import AppSelect from '../../../utils/ui/inputs/AppSelect';

type IBotListFiltersProps = Pick<ActionProps, 'onSearchChange' | 'onActionClick' | 'onSelectChange'>;

const BotListFilters: FC<IBotListFiltersProps> = ({ onSearchChange, onActionClick, onSelectChange }): JSX.Element => {
  const [selectItems, setSelectItems] = useState<AppSelectValueProp[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>('');

  const { t } = useTranslation(['INPUTS', 'BOT_LIST']);

  useEffect((): void => {
    setSelectItems([
      { label: t('INPUTS:published'), value: BotPublishedState.BOT_PUBLISHED },
      { label: t('INPUTS:unpublished'), value: BotPublishedState.BOT_UNPUBLISHED }
    ]);
    setSelectedValue(BotPublishedState.BOT_PUBLISHED);
  }, [t]);

  const onStatusChange = (selection: string): void => {
    setSelectedValue(selection);
    onSelectChange(selection);
  };

  return (
    <Grid2 container spacing={3} alignItems="end">
      <Grid2 xs={12} sm={4}>
        <AppSearchInput onSearchChange={onSearchChange} />
      </Grid2>
      <Grid2 xs={12} sm={4}>
        <AppSelect values={selectItems} selectedValue={selectedValue} onSelectChange={onStatusChange} />
      </Grid2>
      <Grid2 xs={12} sm={4}>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            alignItems: 'end',
            justifyContent: 'end'
          }}>
          <HasAnyPolicy policies={[Policy.MUTABLE_BOT, Policy.MUTABLE_BOT_IN_ORGANIZATION]}>
            <Button variant="contained" startIcon={<AddCircleIcon />} sx={{ pl: 3, pr: 3 }} onClick={onActionClick}>
              {t('BOT_LIST:bot_create')}
            </Button>
          </HasAnyPolicy>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default memo(BotListFilters);
