import SearchIcon from '@mui/icons-material/Search';
import { FilledInput, FilledInputProps, FormControl, IconButton, InputAdornment, styled } from '@mui/material';
import { FC, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionProps } from '../../props/ActionProps';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FilledRoundedTextField = styled(FilledInput)<FilledInputProps>(() => ({
  '&.MuiFilledInput-root': {
    borderRadius: '32px',
    paddingLeft: '16px',
    paddingRight: '16px'
  }
}));

type IAppSearchInputProps = Pick<ActionProps, 'onSearchChange'>;

const AppSearchInput: FC<IAppSearchInputProps> = ({ onSearchChange }): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('INPUTS');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onSearchChange(event.target.value.trim());
  };

  const handleSearchIconClick = (): void => {
    if (ref.current !== null) {
      onSearchChange(ref.current.value.trim());
    }
  };

  return (
    <FormControl variant="filled" fullWidth>
      <FilledRoundedTextField
        hiddenLabel={true}
        margin="dense"
        inputRef={ref}
        placeholder={t('search')}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleSearchIconClick} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        aria-label="search-input"
      />
    </FormControl>
  );
};

export default memo(AppSearchInput);
