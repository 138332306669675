import { Bot } from '../../../domain/models/bot/bot';

export interface IBotState {
  all: Bot[];
  isFirstLoading: boolean;
  current: Bot | null;
  isFirstLoadingCurrent: boolean;
}

export const initialBotState: IBotState = {
  all: [],
  isFirstLoading: true,
  current: null,
  isFirstLoadingCurrent: true
};
