import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

type ITrashIconProps = SvgIconProps;

const TrashIcon: FC<ITrashIconProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="-3 -2 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44444 0.888889V0H9.77778V0.888889H14.2222V2.66667H13.3333V14.2222C13.3333 15.2041 12.5374 16 11.5556 16H2.66667C1.68483 16 0.888889 15.2041 0.888889 14.2222V2.66667H0V0.888889H4.44444ZM11.5556 2.66667H2.66667V14.2222H11.5556V2.66667ZM4.44444 4.44444V12.4444H6.22222V4.44444H4.44444ZM8 12.4444V4.44444H9.77778V12.4444H8Z"
      />
    </SvgIcon>
  );
};

export default memo(TrashIcon);
