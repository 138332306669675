import { Box, Typography } from '@mui/material';
import { FC, memo, ReactNode } from 'react';
import { Policy } from '../../../../../domain/models/policies';
import HasAnyPolicy from '../../auth/HasAnyPolicy';

type IAppDataGridCellActionsProps = {
  id: string;
  children: ReactNode;
};

const AppDataGridCellActions: FC<IAppDataGridCellActionsProps> = ({ children }): JSX.Element => {
  return (
    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
      <HasAnyPolicy
        policies={[Policy.MUTABLE_BOT, Policy.MUTABLE_BOT_IN_ORGANIZATION]}
        alternative={<Typography>-</Typography>}>
        {children}
        {/* <IconButton aria-label="settings" sx={{ mr: 1 }} onClick={(): void => onSettings(id)}>
          <SettingsVariantIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={(): void => onDelete(id)}>
          <TrashIcon color="error" />
        </IconButton> */}
      </HasAnyPolicy>
    </Box>
  );
};

export default memo(AppDataGridCellActions);
