import { useDocumentTitle } from 'usehooks-ts';

export type IUseDomTitle = {
  updateTitle: (title: string) => void;
};

export default function useDomTitle(title: string): IUseDomTitle {
  useDocumentTitle(title);

  const updateTitle = (newTitle: string): void => {
    window.document.title = newTitle;
  };

  return {
    updateTitle
  };
}
