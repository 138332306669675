import moment from 'moment';
import { Bot } from '../../../../models';
import { BotDTO } from '../../dto/bot/bot.dto';

export const fromBotDTOToBot = (dto: BotDTO): Bot => {
  return {
    id: dto.id,
    external: dto.external,
    name: dto.name,
    type: dto.type,
    organization: dto.organization,
    projects: dto.projects,
    status: dto.status,
    description: dto.description,
    defaultLanguage: dto.defaultLanguage,
    supportedLanguages: dto.supportedLanguages,
    location: dto.location,
    timezone: dto.timezone,
    publishState: dto.publishState,
    createdAt: moment(dto.createdAt).valueOf(),
    updatedAt: moment(dto.updatedAt).valueOf()
  };
};
