import {
  FormControl,
  InputLabel,
  inputLabelClasses,
  InputLabelProps,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  styled,
  SxProps,
  Theme,
  Typography
} from '@mui/material';
import { FC, memo, ReactNode } from 'react';
import { AppSelectValueProp } from '../../../../domain/models/inputs';
import { ActionProps } from '../../props/ActionProps';
import AppLabelForm, { IAppLabelFormProps } from './AppLabelForm';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const RoundedSelect = styled(Select)<SelectProps>(({ theme }) => ({
  '&.MuiFilledInput-root': {
    borderRadius: '5px'
  },
  '& .MuiFilledInput-input': {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main
  }
}));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const StyledInputLabel = styled(InputLabel)<InputLabelProps>(() => ({
  shrink: true,
  [`&.${inputLabelClasses.root}`]: {
    padding: 0,
    lineHeight: '1.2rem',
    top: '-6px'
  },
  [`&.${inputLabelClasses.focused}`]: {
    transform: 'translate(12px, 16px) scale(1)',
    maxWidth: 'calc(100% - 24px)'
  }
}));

type IAppSelectProps = {
  label?: string;
  labelColor?: string;
  isSolo?: boolean;
  values: AppSelectValueProp[];
  selectedValue?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  sxSelect?: SxProps<Theme>;
} & Pick<ActionProps, 'onSelectChange'> &
  Omit<IAppLabelFormProps, 'label'>;

const AppSelect: FC<IAppSelectProps> = ({
  label,
  labelColor,
  labelCapitalize,
  values,
  error = false,
  required = false,
  isSolo = false,
  selectedValue = '',
  placeholder,
  sx,
  sxSelect,
  onSelectChange
}): JSX.Element => {
  const handleChange = (event: SelectChangeEvent<unknown>, _child: ReactNode): void => {
    onSelectChange(event.target.value as string);
  };

  return (
    <div>
      {label && isSolo && (
        <Typography variant="body2" sx={{ mb: '6px', fontSize: '12px' }}>
          {label}
        </Typography>
      )}
      <FormControl variant="filled" sx={{ minWidth: 120, ...sx }} data-testid="app-form-select">
        {label && !isSolo && (
          <AppLabelForm
            label={label}
            color={labelColor}
            labelCapitalize={labelCapitalize}
            required={required}
            error={error}
          />
        )}
        {placeholder && <StyledInputLabel>{placeholder}</StyledInputLabel>}
        <RoundedSelect
          value={selectedValue}
          onChange={handleChange}
          disableUnderline
          margin="dense"
          sx={sxSelect}
          data-testid="app-select">
          {values.map(
            (v): JSX.Element => (
              <MenuItem key={v.value} value={v.value}>
                {v.label}
              </MenuItem>
            )
          )}
        </RoundedSelect>
      </FormControl>
    </div>
  );
};

export default memo(AppSelect);
