import { BotIntent } from '../../../domain/models/intent';

export interface IintentState {
  all: BotIntent[];
  isFirstLoading: boolean;
}

export const initialIntentState: IintentState = {
  all: [],
  isFirstLoading: true
};
