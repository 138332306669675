import { SxProps, Theme, useTheme } from '@mui/material';
import React from 'react';
import AppLabel, { IAppLabelProps } from '../data-display/typography/AppLabel';

export type IAppLabelFormProps = {
  error?: boolean;
  sx?: SxProps<Theme>;
} & IAppLabelProps;

const AppLabelForm: React.FC<IAppLabelFormProps> = (props): JSX.Element => {
  const theme = useTheme();
  const { error = false, color, ...otherProps } = props;

  return <AppLabel color={error ? theme.palette.error.main : color} {...otherProps} />;
};

export default React.memo(AppLabelForm);
