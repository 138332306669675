import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BotIntent } from '../../../domain/models/intent';
import { IintentState, initialIntentState } from '../model/intentState';

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const intentSlice = createSlice({
  name: 'intents',
  initialState: initialIntentState,
  reducers: {
    setAllIntents: (state: IintentState, { payload }: PayloadAction<BotIntent[]>): void => {
      state.all = payload;
    },
    setIntentLoading: (state: IintentState): void => {
      state.isFirstLoading = true;
    },
    unSetIntentLoading: (state: IintentState): void => {
      state.isFirstLoading = false;
    }
  }
});

export default intentSlice.reducer;
