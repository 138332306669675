import SourceIcon from '@mui/icons-material/Source';
import { Box, Button, FormHelperText, Typography, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../data/store';
import { selectAllProjects } from '../../../../data/store/selectors/projectSelectors';
import { BotLanguage, BotType } from '../../../../domain/models/bot/bot';
import { MutateBot } from '../../../../domain/models/bot/mutatebot';
import { AppSelectValueProp } from '../../../../domain/models/inputs';
import useLoadAllProjectsUseCase from '../../../../domain/usecases/project/useLoadAllProjectsUseCase';
import { FormValidationRules } from '../../../utils/props/FormProps';
import AppLabel from '../../../utils/ui/data-display/typography/AppLabel';
import AppAutoComplete from '../../../utils/ui/inputs/AppAutoComplete';
import AppFilledInput from '../../../utils/ui/inputs/AppFilledInput';
import AppSelect from '../../../utils/ui/inputs/AppSelect';
import { AutocompleteOption } from '../../../utils/ui/inputs/autocomplete.model';

interface ICreateBotFormInput {
  name: string;
  defaultLanguage: BotLanguage;
  projects: AutocompleteOption[]; // react-hook-form doesn't support array of primitives, I must use an object to wrap data.
}

type IBotCreateFormProps = {
  onSubmit: (data: Omit<MutateBot, 'organization'>) => void;
  onCancel: () => void;
};

const BotCreateForm: React.FC<IBotCreateFormProps> = ({ onSubmit, onCancel }): JSX.Element => {
  const { t, i18n } = useTranslation(['BOT_FORM', 'LANGUAGES']);
  const theme = useTheme();
  const projects = useAppSelector(selectAllProjects);
  const { loadProjects } = useLoadAllProjectsUseCase();
  const [isProjectsReplaced, setIsProjectsReplaced] = useState(false);

  const validationRules: FormValidationRules<ICreateBotFormInput> = {
    name: {
      required: {
        value: true,
        message: t('name_required_error_message')
      }
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<ICreateBotFormInput>({
    defaultValues: {
      name: '',
      defaultLanguage: i18n.language.split('-')[0] as BotLanguage,
      projects: []
    },
    shouldUnregister: true,
    reValidateMode: 'onBlur',
    mode: 'all'
  });

  const watchProjects = watch('projects', []);

  const { replace: replaceProjects } = useFieldArray({
    control,
    name: `projects`,
    shouldUnregister: true
  });

  const projectsAsOptions = useMemo((): AutocompleteOption[] => {
    const selected = watchProjects.map((p): string => p.value);
    return projects
      .filter((p): boolean => !selected.includes(p.id))
      .map(
        (p): AutocompleteOption => ({
          title: p.name,
          value: p.id
        })
      );
  }, [projects, watchProjects]);

  const availableLanguages = useMemo((): AppSelectValueProp[] => {
    return [
      {
        label: t(`LANGUAGES:it`),
        value: 'it'
      },
      {
        label: t(`LANGUAGES:en`),
        value: 'en'
      }
    ];
  }, [i18n.language]);

  const onSubmitInner = (data: ICreateBotFormInput): void => {
    onSubmit({
      defaultLanguage: data.defaultLanguage,
      location: 'europe-west1',
      name: data.name,
      description: '',
      supportedLanguages: [],
      timezone: 'Europe/Madrid',
      type: BotType.BOT_TYPE_SHARED,
      projects: data.projects.map((item): string => item.value)
    });
  };

  useEffect((): void => {
    loadProjects();
  }, []);

  useEffect((): void => {
    if (projectsAsOptions.length > 0 && !isProjectsReplaced) {
      replaceProjects(projectsAsOptions);
      setIsProjectsReplaced(true);
    }
  }, [projectsAsOptions]);

  return (
    <form onSubmit={handleSubmit(onSubmitInner)}>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={6}>
          <Controller
            name="name"
            control={control}
            rules={validationRules.name}
            render={({ field }): JSX.Element => (
              <AppFilledInput
                fullWidth
                required
                color="secondary"
                label={t('name')}
                labelColor={theme.palette.text.secondary}
                placeholder={t('name_placeholder')}
                error={errors.name?.type === 'required'}
                helperText={errors.name?.message}
                {...field}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <Controller
            name="defaultLanguage"
            control={control}
            render={({ field }): JSX.Element => (
              <AppSelect
                required
                color={theme.palette.text.secondary}
                label={t('default_language')}
                labelColor={theme.palette.text.secondary}
                values={availableLanguages}
                selectedValue={field.value}
                onSelectChange={(selection: string): void => {
                  field.onChange(selection);
                }}
                {...field}
              />
            )}
          />
        </Grid2>
        <Grid2 xs={12} md={6}>
          <AppLabel label={t('location')} labelCapitalize required color={theme.palette.text.secondary} />
          <Typography>{t('LOCATION:location')}</Typography>
        </Grid2>
        <Grid2 xs={12} md={6}>
          <AppLabel label={t('timezone')} labelCapitalize required color={theme.palette.text.secondary} />
          <Typography>{t('LOCATION:timezone')}</Typography>
        </Grid2>
        <Grid2 xs={12}>
          <AppLabel
            label={`${t('associate_projects')}:`}
            labelCapitalize
            startIcon={<SourceIcon />}
            color={theme.palette.text.secondary}
          />
          <Controller
            name="projects"
            control={control}
            defaultValue={projectsAsOptions}
            render={({ field: projectsField }): JSX.Element => (
              <AppAutoComplete
                fullWidth
                clearOnBlur
                placeholder={t('add_projects')}
                options={projectsAsOptions}
                {...projectsField}
                value={projectsField.value}
                onChange={(_event, data): void => projectsField.onChange(data)}
              />
            )}
          />
          <FormHelperText>{t('INPUTS:multiple_select_hint')}</FormHelperText>
        </Grid2>
      </Grid2>
      <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', justifyContent: 'end', mt: 10, mr: 2 }}>
        <Button onClick={onCancel} variant="secondary" sx={{ mr: 4 }}>
          {t('ACTIONS:cancel')}
        </Button>
        <Button type="submit" variant="contained" className="text--capitalized" sx={{ px: 5 }}>
          {t('ACTIONS:save')}
        </Button>
      </Box>
    </form>
  );
};

export default React.memo(BotCreateForm);
