import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Bot } from '../../../domain/models/bot/bot';
import { IBotState, initialBotState } from '../model/botState';

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const botSlice = createSlice({
  name: 'bots',
  initialState: initialBotState,
  reducers: {
    setAllBots: (state: IBotState, { payload }: PayloadAction<Bot[]>): void => {
      state.all = payload;
    },
    removeBotsInList: (state: IBotState, { payload }: PayloadAction<string[]>): void => {
      state.all = state.all.filter((b): boolean => !payload.includes(b.id));
    },
    removeBot: (state: IBotState, { payload }: PayloadAction<string>): void => {
      state.all = state.all.filter((b): boolean => payload !== b.id);
    },
    addBot: (state: IBotState, { payload }: PayloadAction<Bot>): void => {
      state.all.push(payload);
    },
    replaceBot: (state: IBotState, { payload }: PayloadAction<Bot>): void => {
      state.all = state.all.filter((s): boolean => s.id !== payload.id).concat(payload);
    },
    setCurrentBot: (state: IBotState, { payload }: PayloadAction<Bot>): void => {
      state.current = payload;
    },
    setCurrentBotIfNotExists: (state: IBotState, { payload }: PayloadAction<Bot>): void => {
      state.current = state.current === null ? payload : state.current;
    },
    setCurrentBotFromId: (state: IBotState, { payload }: PayloadAction<string>): void => {
      state.current = state.all.find((b): boolean => b.id === payload) ?? null;
    },
    unsetCurrentBot: (state: IBotState): void => {
      state.current = null;
    },
    setBotLoading: (state: IBotState): void => {
      state.isFirstLoading = true;
    },
    unSetBotLoading: (state: IBotState): void => {
      state.isFirstLoading = false;
    },
    setCurrentBotLoading: (state: IBotState): void => {
      state.isFirstLoadingCurrent = true;
    },
    unSetCurrentBotLoading: (state: IBotState): void => {
      state.isFirstLoadingCurrent = false;
    }
  }
});

export default botSlice.reducer;
