import { AxiosResponse } from 'axios';
import { ApiClient } from '../../../domain/http/augmentya/apiClient';
import { BotDTO } from '../../../domain/http/augmentya/dto/bot/bot.dto';
import { ResponseApiDTO } from '../../../domain/http/augmentya/dto/response.dto';
import { fromBotDTOToBot } from '../../../domain/http/augmentya/mapper/bot/fromBotDTOToBot';
import { fromMutateBotToMutateBotDTO } from '../../../domain/http/augmentya/mapper/bot/fromMutateBotToMutateBotDTO';
import { Bot } from '../../../domain/models/bot/bot';
import { botSlice } from '../reducers/botReducer';
import { MutateBotDTO } from './../../../domain/http/augmentya/dto/bot/mutatebot.dto';
import { MutateBotWithoutOrganization } from './../../../domain/models/bot/mutatebot';
import { AppDispatch, IStore } from './../store';
import { setStartLoading, setStopLoading } from './appActions';
import { handleLicenseErrorResponse, handleSimpleErrorReponse } from './utils';

export const {
  setCurrentBotIfNotExists,
  setCurrentBot,
  removeBotsInList,
  removeBot,
  addBot,
  setCurrentBotFromId,
  unsetCurrentBot,
  setAllBots,
  setBotLoading,
  unSetBotLoading,
  setCurrentBotLoading,
  unSetCurrentBotLoading,
  replaceBot
} = botSlice.actions;

/**
 * Fetch remote list of bots
 *
 * @param organizationId Id of organization
 */
export const fetchAllBotOfOrganization =
  (organizationId: string, publishState?: string) =>
  async (dispatch: AppDispatch, getState: () => IStore): Promise<boolean> => {
    const currentBot = getState().bots.current;

    dispatch(setBotLoading());
    try {
      const params = publishState ? { publishState } : undefined;

      const { data } = await ApiClient.getInstance().apiClient.get<ResponseApiDTO<BotDTO[]>>(
        `/organizations/${organizationId}/bots`,
        {
          params
        }
      );

      const bots = data.data.map((b): Bot => fromBotDTOToBot(b));

      dispatch(setAllBots(bots));

      // Unset current bot when publishstate of request is changed
      if (publishState && currentBot?.publishState !== publishState) {
        dispatch(unsetCurrentBot());
      }

      // Set current bot
      if (bots.length > 0) {
        dispatch(setCurrentBotIfNotExists([...bots].sort((a, b): number => a.name.localeCompare(b.name))[0] as Bot));
      } else {
        dispatch(unsetCurrentBot());
      }
      return true;
    } catch (e: unknown) {
      handleSimpleErrorReponse(dispatch, e);
      return false;
    } finally {
      dispatch(unSetBotLoading());
    }
  };

/**
 * Get Remote bot and select as current
 *
 * @param botId Id of bot
 */
export const fetchBot =
  (botId: string) =>
  async (dispatch: AppDispatch, getState: () => IStore): Promise<Bot | null> => {
    const currentOrganization = getState().auth.currentOrganization;
    const allBots = getState().bots.all;

    dispatch(setCurrentBotLoading());
    try {
      const { data } = await ApiClient.getInstance().apiClient.get<ResponseApiDTO<BotDTO>>(
        `/organizations/${currentOrganization?.id}/bots/${botId}`
      );

      const bot = fromBotDTOToBot(data.data);

      if (allBots.find((bot): boolean => bot.id === botId)) {
        dispatch(replaceBot(bot));
      } else {
        dispatch(addBot(bot));
      }

      return bot;
    } catch (e: unknown) {
      handleSimpleErrorReponse(dispatch, e);
      return null;
    } finally {
      dispatch(unSetCurrentBotLoading());
    }
  };

/**
 * Create new remote Bot for current organization
 *
 * @param body Payload of Bot without currentOrganization
 */
export const createBotOfOrganization =
  (body: MutateBotWithoutOrganization) =>
  async (dispatch: AppDispatch, getState: () => IStore): Promise<boolean> => {
    const currentOrganization = getState().auth.currentOrganization;

    dispatch(setStartLoading());
    try {
      if (currentOrganization === null) {
        throw Error('No organization selected');
      }

      const { data } = await ApiClient.getInstance().apiClient.post<
        ResponseApiDTO<BotDTO>,
        AxiosResponse<ResponseApiDTO<BotDTO>>,
        MutateBotDTO
      >(
        `/organizations/${currentOrganization.id}/bots`,
        fromMutateBotToMutateBotDTO({ ...body, organization: currentOrganization.id })
      );

      dispatch(addBot(fromBotDTOToBot(data.data)));

      return true;
    } catch (e: unknown) {
      handleLicenseErrorResponse(dispatch, e);
      return false;
    } finally {
      dispatch(setStopLoading());
    }
  };

/**
 * Edit existing remote Bot for current organization
 *
 * @param botId Id of updated bot
 * @param body Payload of Bot without currentOrganization
 */
export const updateBotOfOrganization =
  (botId: string, body: MutateBotWithoutOrganization) =>
  async (dispatch: AppDispatch, getState: () => IStore): Promise<boolean> => {
    const currentOrganization = getState().auth.currentOrganization;
    const allBots = getState().bots.all;

    dispatch(setStartLoading());
    try {
      if (currentOrganization === null) {
        throw Error('No organization selected');
      }

      const { data } = await ApiClient.getInstance().apiClient.put<
        ResponseApiDTO<BotDTO>,
        AxiosResponse<ResponseApiDTO<BotDTO>>,
        MutateBotDTO
      >(
        `/organizations/${currentOrganization.id}/bots/${botId}`,
        fromMutateBotToMutateBotDTO({ ...body, organization: currentOrganization.id })
      );

      const bot = fromBotDTOToBot(data.data);

      if (allBots.find((bot): boolean => bot.id === botId)) {
        dispatch(replaceBot(bot));
      } else {
        dispatch(addBot(bot));
      }

      return true;
    } catch (e: unknown) {
      handleSimpleErrorReponse(dispatch, e);
      return false;
    } finally {
      dispatch(setStopLoading());
    }
  };

/**
 * Delete single bot
 *
 * @param botId Id of bot to delete
 */
export const deleteBotOfOrganization =
  (botId: string) =>
  async (dispatch: AppDispatch, getState: () => IStore): Promise<boolean> => {
    const currentOrganization = getState().auth.currentOrganization;
    const currentBot = getState().bots.current;

    dispatch(setStartLoading());
    try {
      if (currentOrganization === null) {
        throw Error('No organization selected');
      }

      await ApiClient.getInstance().apiClient.delete<null, AxiosResponse<null>>(
        `/organizations/${currentOrganization.id}/bots/${botId}`
      );

      dispatch(removeBot(botId));

      // Unset current bot if is in delete list
      if (botId === currentBot?.id) {
        dispatch(unsetCurrentBot());
      }

      return true;
    } catch (e: unknown) {
      handleSimpleErrorReponse(dispatch, e);
      return false;
    } finally {
      dispatch(setStopLoading());
    }
  };

/**
 * Delete multiple bots
 *
 * @param bots List of bots to delete
 */
export const deleteMassiveBots =
  (bots: string[]) =>
  async (dispatch: AppDispatch, getState: () => IStore): Promise<boolean> => {
    const currentOrganization = getState().auth.currentOrganization;
    const currentBot = getState().bots.current;

    dispatch(setStartLoading());
    try {
      if (currentOrganization === null) {
        throw Error('No organization selected');
      }

      await ApiClient.getInstance().apiClient.delete<null, AxiosResponse<null>, { listId: string[] }>(
        `/organizations/${currentOrganization.id}/bots`,
        {
          data: {
            listId: bots
          }
        }
      );

      dispatch(removeBotsInList(bots));

      // Unset current bot if is in delete list
      if (currentBot !== null && bots.includes(currentBot.id)) {
        dispatch(unsetCurrentBot());
      }

      return true;
    } catch (e: unknown) {
      handleSimpleErrorReponse(dispatch, e);
      return false;
    } finally {
      dispatch(setStopLoading());
    }
  };
