import { Typography, useTheme } from '@mui/material';
import { FC, memo } from 'react';

type IAppFeedbackTitleProps = {
  text: string;
};

const AppFeedbackTitle: FC<IAppFeedbackTitleProps> = ({ text }): JSX.Element => {
  const theme = useTheme();

  return (
    <Typography
      variant="h5"
      align="center"
      mt={10}
      mb={3}
      color={theme.palette.text.secondary}
      aria-label="feedback-title">
      {text}
    </Typography>
  );
};

export default memo(AppFeedbackTitle);
