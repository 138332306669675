import React, { FC, memo, useEffect } from 'react';
import useAuth from '../../../../domain/hooks/authorization/useAuth';
import useCustomNavigation from '../../hooks/navigation/useCustomNavigation';
import { ChildrenProps } from '../../props/ChildrenProps';

type IRequireAuthProps = ChildrenProps;

const RequireAuth: FC<IRequireAuthProps> = ({ children }): JSX.Element => {
  const { isLogged } = useAuth();
  const { navigate } = useCustomNavigation();

  useEffect((): void => {
    if (!isLogged()) {
      navigate('/error', { replace: true });
    }
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};

export default memo(RequireAuth);
