/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { Policy } from './domain/models/policies';
import BotList from './presentation/ui/bots/list/BotList';
import RequireAuth from './presentation/utils/ui/auth/RequireAuth';
import RequireHasAnyPolicy from './presentation/utils/ui/auth/RequireHasAnyPolicy';
import AppSuspense from './presentation/utils/ui/feedback/AppSuspense';
import GlobalPageWrapper from './presentation/utils/ui/layout/GlobalPageWrapper';

// https://reactjs.org/docs/code-splitting.html#reactlazy
// Lazy loading of some components
const ErrorPage = React.lazy(() => import('./presentation/utils/ui/error/ErrorPage'));
const EntityList = React.lazy(() => import('./presentation/ui/entites/list/EntityList'));
const EntityCreate = React.lazy(() => import('./presentation/ui/entites/create/EntityCreate'));
const EntityUpdate = React.lazy(() => import('./presentation/ui/entites/update/EntityUpdate'));
const IntentList = React.lazy(() => import('./presentation/ui/intents/list/IntentList'));
const IntentCreate = React.lazy(() => import('./presentation/ui/intents/create/IntentCreate'));
const BotBuilderContainer = React.lazy(() => import('./presentation/ui/bots/builder/BotBuilderContainer'));
const BotSettingsContainer = React.lazy(() => import('./presentation/ui/bots/settings/BotSettingsContainer'));

/**
 * Routes of Application.
 *
 * Please refers to {@link https://reactrouter.com/en/main/start/tutorial Tutorial} and
 * {@link https://github.com/remix-run/react-router/blob/4d915e3305df5b01f51abdeb1c01bf442453522e/examples/route-objects/src/App.tsx Example}
 */
export const routes: RouteObject[] = [
  {
    path: '/',
    errorElement: (
      <AppSuspense>
        <ErrorPage />
      </AppSuspense>
    ),
    element: <Outlet />
  },
  {
    path: '/assistant/:organizationId',
    element: (
      <RequireAuth>
        <RequireHasAnyPolicy
          policies={[
            Policy.VIEW_BOT,
            Policy.VIEW_BOT_IN_ORGANIZATION,
            Policy.VIEW_BOT_ALL_IN_ORGANIZATION,
            Policy.MUTABLE_BOT,
            Policy.MUTABLE_BOT_IN_ORGANIZATION
          ]}>
          <GlobalPageWrapper>
            <Outlet />
          </GlobalPageWrapper>
        </RequireHasAnyPolicy>
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        index: true, // Default component for parent route
        element: <BotList />
      },
      {
        path: 'bots/:botId',
        element: (
          <RequireHasAnyPolicy policies={[Policy.MUTABLE_BOT, Policy.MUTABLE_BOT_IN_ORGANIZATION]}>
            <AppSuspense>
              <Outlet />
            </AppSuspense>
          </RequireHasAnyPolicy>
        ),
        children: [
          {
            path: '',
            index: true,
            element: <BotBuilderContainer />
          },
          {
            path: 'settings',
            element: <BotSettingsContainer />
          }
        ]
      },
      {
        path: 'entities',
        element: (
          <RequireHasAnyPolicy policies={[Policy.MUTABLE_BOT, Policy.MUTABLE_BOT_IN_ORGANIZATION]}>
            <AppSuspense>
              <Outlet />
            </AppSuspense>
          </RequireHasAnyPolicy>
        ),
        children: [
          {
            path: '',
            index: true,
            element: <EntityList />
          },
          {
            path: 'create',
            element: <EntityCreate />
          },
          {
            path: ':entityId',
            element: <EntityUpdate />
          }
        ]
      },
      {
        path: 'intents',
        element: (
          <RequireHasAnyPolicy policies={[Policy.MUTABLE_BOT, Policy.MUTABLE_BOT_IN_ORGANIZATION]}>
            <AppSuspense>
              <Outlet />
            </AppSuspense>
          </RequireHasAnyPolicy>
        ),
        children: [
          {
            path: '',
            index: true,
            element: <IntentList />
          },
          {
            path: 'create',
            element: <IntentCreate />
          }
        ]
      }
    ]
  }
];
