import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, CSSObject, IconButton, List, styled, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../data/store';
import { selectCurrentOrganization } from '../../../../data/store/selectors/authSelectors';
import useHasPolicy from '../../../../domain/hooks/authorization/useHasPolicy';
import useCurrentRoute from '../../../../domain/hooks/navigation/useCurrentRoute';
import { Policy } from '../../../../domain/models/policies';
import useDrawer from '../../hooks/drawer/useDrawer';
import useCustomNavigation from '../../hooks/navigation/useCustomNavigation';
import { ChildrenProps } from '../../props/ChildrenProps';
import BaloonIcon from '../icons/BaloonIcon';
import BotIcon from '../icons/BotIcon';
import EditIcon from '../icons/EditIcon';

const drawerWidth = 246;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  boxSizing: 'border-box',
  position: 'relative',
  height: `calc(100vh - 65px)`
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: `81px`
  },
  boxSizing: 'border-box',
  position: 'relative',
  height: `calc(100vh - 65px)`
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop): boolean => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiListItemButton-root': {
      paddingLeft: 36
    },
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiListItemButton-root': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 26
      }
    },
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

type MenuItemType = {
  label: string;
  icon: ReactNode;
  link: string;
  policies: Policy[];
};

const menuItems: MenuItemType[] = [
  {
    label: 'bots',
    icon: <BotIcon />,
    link: '',
    policies: [Policy.VIEW_BOT, Policy.VIEW_BOT_ALL_IN_ORGANIZATION, Policy.VIEW_BOT_IN_ORGANIZATION]
  },
  {
    label: 'entities',
    icon: <EditIcon />,
    link: 'entities',
    policies: [Policy.MUTABLE_BOT, Policy.MUTABLE_BOT_IN_ORGANIZATION]
  },
  {
    label: 'intents',
    icon: <BaloonIcon />,
    link: 'intents',
    policies: [Policy.MUTABLE_BOT, Policy.MUTABLE_BOT_IN_ORGANIZATION]
  }
];

type IAppSidebarProps = ChildrenProps;

const AppLayoutWithDrawer: FC<IAppSidebarProps> = ({ children }): JSX.Element => {
  const { t } = useTranslation(['DRAWER']);
  const { hasAnyPolicies } = useHasPolicy();
  const { open, toggleDrawer } = useDrawer();
  const { currentRoute } = useCurrentRoute();
  const currentOrganization = useAppSelector(selectCurrentOrganization);
  const { navigate } = useCustomNavigation();

  const goToRoute = (link: string): void => {
    navigate(link);
  };

  return (
    <Box sx={{ display: 'flex' }} className="app-drawer__container">
      <Drawer variant="permanent" anchor="left" open={open}>
        <Box sx={{ height: 100, mt: 1, display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
          <IconButton
            sx={{
              backgroundColor: '#ffffff14'
            }}
            onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>
        <List>
          {menuItems.map(
            (item): JSX.Element | false =>
              hasAnyPolicies(item.policies) && (
                <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    selected={item.label === currentRoute}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5
                    }}
                    onClick={(): void => goToRoute(`/assistant/${currentOrganization?.id}/${item.link}`)}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                      }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={t<string>(item.label)} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              )
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
};

export default memo(AppLayoutWithDrawer);
