import { createSelector } from '@reduxjs/toolkit';
import { User } from '../../../domain/models';
import { Organization } from '../../../domain/models/organization';
import { Policy } from '../../../domain/models/policies';
import { Role } from '../../../domain/models/role';
import { IAuthState } from '../model/authState';
import { RootState } from '../store';

// For memoization look this https://react-redux.js.org/api/hooks#using-memoizing-selectors

const selectState = (state: RootState): IAuthState => state.auth;
export const selectCurrentUser = createSelector([selectState], (store): User | null => store.currentUser);

export const selectCurrentRole = createSelector([selectState], (store): Role | null => store.currentRole);

export const selectCurrentUserWithRole = createSelector(
  [selectCurrentUser, selectCurrentRole],
  (user, role): User | null => {
    if (user === null) {
      return null;
    } else {
      return {
        ...user,
        role: role?.name ?? ''
      };
    }
  }
);

export const selectToken = createSelector([selectState], (store): string | null => store.currentUser?.token ?? null);

export const selectCurrentPolicies = createSelector([selectCurrentUser], (user): Policy[] => user?.policies ?? []);

export const selectCurrentOrganization = createSelector(
  [selectState],
  (store): Organization | null => store.currentOrganization
);
