import { useAppDispatch } from '../../../data/store';
import { setAppMessage } from '../../../data/store/actions/appActions';
import { deleteBotOfOrganization } from '../../../data/store/actions/botActions';

type IUseDeleteBotUseCase = {
  deleteBot: (botId: string) => Promise<boolean>;
};

export default function useDeleteBotUseCase(): IUseDeleteBotUseCase {
  const dispatch = useAppDispatch();
  const deleteBot = async (botId: string): Promise<boolean> => {
    const res = await dispatch(deleteBotOfOrganization(botId));
    if (res) {
      dispatch(
        setAppMessage({
          type: 'success',
          text: 'BOT.delete_success'
        })
      );
    }

    return res;
  };

  return {
    deleteBot
  };
}
