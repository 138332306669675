import { Project } from './../../../../models/project';
import { ProjectDTO } from './../../dto/project.dto';

export const fromProjectDTOToProject = (dto: ProjectDTO): Project => {
  return {
    id: dto.id,
    code: dto.code,
    description: dto.description,
    groups: dto.groups,
    image: dto.image,
    name: dto.name,
    organization: dto.organization,
    users: dto.users
  };
};
