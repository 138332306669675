import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

type IBotIconProps = SvgIconProps;

const BotIcon: FC<IBotIconProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="-1 -1 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2727 9.63009H14.5455C14.5455 6.52439 12.2691 4.01254 9.45455 4.01254H8.72727V2.99335C9.16364 2.7205 9.45455 2.19887 9.45455 1.60502C9.45455 0.722257 8.80727 0 8 0C7.19273 0 6.54545 0.722257 6.54545 1.60502C6.54545 2.19887 6.83636 2.7205 7.27273 2.99335V4.01254H6.54545C3.73091 4.01254 1.45455 6.52439 1.45455 9.63009H0.727273C0.327273 9.63009 0 9.99122 0 10.4326V12.8401C0 13.2815 0.327273 13.6426 0.727273 13.6426H1.45455V14.4451C1.45455 15.3359 2.10909 16.0502 2.90909 16.0502H13.0909C13.8982 16.0502 14.5455 15.3359 14.5455 14.4451V13.6426H15.2727C15.6727 13.6426 16 13.2815 16 12.8401V10.4326C16 9.99122 15.6727 9.63009 15.2727 9.63009ZM6.39273 11.6364C6.10909 10.9302 5.47636 10.4326 4.72727 10.4326C3.97818 10.4326 3.34545 10.9302 3.06182 11.6364C2.96727 11.3876 2.90909 11.1228 2.90909 10.8339C2.90909 9.72639 3.72364 8.82759 4.72727 8.82759C5.73091 8.82759 6.54545 9.72639 6.54545 10.8339C6.54545 11.1228 6.48727 11.3876 6.39273 11.6364ZM11.2727 10.4326C12 10.4326 12.6545 10.9302 12.9382 11.6364C13.0327 11.3876 13.0909 11.1228 13.0909 10.8339C13.0909 9.72639 12.2764 8.82759 11.2727 8.82759C10.2691 8.82759 9.45455 9.72639 9.45455 10.8339C9.45455 11.1228 9.51273 11.3876 9.60727 11.6364C9.89091 10.9302 10.5455 10.4326 11.2727 10.4326Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default memo(BotIcon);
