import {
  FilledInput,
  FilledInputProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  styled,
  SxProps
} from '@mui/material';
import { Theme } from '@mui/system';
import { FC, memo, ReactNode } from 'react';
import AppLabelForm, { IAppLabelFormProps } from './AppLabelForm';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const StyledFilledInput = styled(FilledInput)<FilledInputProps>(() => ({
  '& .MuiFilledInput-input::placeholder': {
    fontStyle: 'italic',
    color: '#FFF',
    fontSize: '12px'
  },
  '&.MuiFilledInput-root.flatOnRight': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  '&.MuiFilledInput-root.flatOnLeft': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}));

type IAppFilledInputProps =
  | Omit<FilledInputProps, 'margin'> &
      FormHelperTextProps &
      Pick<FormControlProps, 'fullWidth' | 'required'> & {
        label?: string;
        labelColor?: string;
        helperText?: ReactNode;
        flatOnRight?: boolean;
        flatOnLeft?: boolean;
        sxLabel?: SxProps<Theme>;
      } & Omit<IAppLabelFormProps, 'label'>;

const AppFilledInput: FC<IAppFilledInputProps> = (props): JSX.Element => {
  const { fullWidth, flatOnRight, flatOnLeft, required, label, helperText, sxLabel, ...inputProps } = props;

  return (
    <FormControl variant="filled" fullWidth={fullWidth ?? false} required={required}>
      {label && (
        <AppLabelForm
          label={label}
          color={props.labelColor}
          labelCapitalize={props.labelCapitalize}
          required={required}
          error={props.error}
          sx={sxLabel}
        />
      )}
      <StyledFilledInput
        margin="dense"
        {...inputProps}
        className={`${flatOnRight ? 'flatOnRight' : ''} ${flatOnLeft ? 'flatOnLeft' : ''}`}
      />
      <FormHelperText error={props.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default memo(AppFilledInput);
