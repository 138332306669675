import { StatusThemeColor } from '../statusThemeColor';

export enum BotType {
  BOT_TYPE_INVALID = 'BOT_TYPE_INVALID',
  BOT_TYPE_SHARED = 'BOT_TYPE_SHARED',
  BOT_TYPE_REGULAR = 'BOT_TYPE_REGULAR',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum BotStatus {
  BOT_STATUS_INVALID = 'BOT_STATUS_INVALID',
  BOT_STATUS_NEW = 'BOT_STATUS_NEW',
  BOT_STATUS_ON_CREATING = 'BOT_STATUS_ON_CREATING',
  BOT_STATUS_CREATED = 'BOT_STATUS_CREATED',
  BOT_STATUS_ERROR = 'BOT_STATUS_ERROR',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum BotPublishedState {
  BOT_PUBLISHED = 'BOT_PUBLISHED',
  BOT_UNPUBLISHED = 'BOT_UNPUBLISHED'
}

export const BotStatusThemeColor: {
  [key in BotStatus]: StatusThemeColor;
} = {
  BOT_STATUS_INVALID: 'warning',
  BOT_STATUS_NEW: 'info',
  BOT_STATUS_ON_CREATING: 'info',
  BOT_STATUS_CREATED: 'success',
  BOT_STATUS_ERROR: 'error',
  UNRECOGNIZED: 'error'
};

export type BotLanguage = 'it' | 'en';
export type BotLocation = 'europe-west1';
export type BotTimezone = 'Europe/Madrid'; // CET - GTM +1, NB: enforce display to Rome

export type Bot = {
  id: string;
  external: string | null;
  name: string;
  type: BotType;
  organization: string;
  projects: string[];
  status: BotStatus;
  description: string;
  defaultLanguage: BotLanguage;
  supportedLanguages: BotLanguage[];
  location: BotLocation;
  timezone: BotTimezone;
  publishState: BotPublishedState;
  createdAt: number;
  updatedAt: number;
};
