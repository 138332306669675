import * as _ from 'lodash';
import { useAppSelector } from '../../../data/store';
import { selectCurrentPolicies } from '../../../data/store/selectors/authSelectors';
import { Policy } from '../../models/policies';

type IuseHasPolicy = {
  hasAnyPolicies: (policies: Policy[]) => boolean;
  hasAllPolicies: (polciies: Policy[]) => boolean;
};

export default function useHasPolicy(): IuseHasPolicy {
  const currentPolcies = useAppSelector(selectCurrentPolicies);

  const hasAny = (policies: Policy[]): boolean => {
    return _.intersection(policies, currentPolcies).length > 0;
  };

  const hasAll = (policies: Policy[]): boolean => {
    return _.intersection(policies, currentPolcies).length === policies.length;
  };

  return { hasAnyPolicies: hasAny, hasAllPolicies: hasAll };
}
