import { Box, Typography } from '@mui/material';
import { FC, Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../data/store';
import { setBreadcrumb } from '../../../../data/store/actions/appActions';
import { fetchAllBotOfOrganization } from '../../../../data/store/actions/botActions';
import { selectCurrentOrganization } from '../../../../data/store/selectors/authSelectors';
import { selectAllBots, selectBotsHaveSkeleton } from '../../../../data/store/selectors/botSelectors';
import useCurrentRoute from '../../../../domain/hooks/navigation/useCurrentRoute';
import { Bot } from '../../../../domain/models';
import { BotPublishedState } from '../../../../domain/models/bot/bot';
import { MutateBotWithoutOrganization } from '../../../../domain/models/bot/mutatebot';
import useCreateBotUseCase from '../../../../domain/usecases/bot/useCreateBotUseCase';
import useDebouncedFilter from '../../../utils/hooks/filters/useDebouncedFilter';
import AppFeedbackTitle from '../../../utils/ui/data-display/typography/AppFeedbackTitle';
import AppDialog from '../../../utils/ui/feedback/AppDialog';
import BotCreateForm from '../create/BotCreateForm';
import BotListFilters from './BotListFilters';
import BotListTable from './table/BotListTable';

const BotList: FC = (): JSX.Element => {
  const { t } = useTranslation('BOT_LIST');
  const dispatch = useAppDispatch();
  const { createBot } = useCreateBotUseCase();
  const currentOrganization = useAppSelector(selectCurrentOrganization);
  const botsHaveSkeleton = useAppSelector(selectBotsHaveSkeleton);
  const bots = useAppSelector(selectAllBots);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [currentBotStatus, setCurrentBotStatus] = useState<string>(BotPublishedState.BOT_PUBLISHED);
  useCurrentRoute('bots');

  const {
    searchHandler,
    data: botsMemo,
    querySearch
  } = useDebouncedFilter<Bot>(bots, ({ name }, querySearch): boolean =>
    name.toLocaleLowerCase().includes(querySearch.toLocaleLowerCase())
  );

  useEffect((): void => {
    if (currentOrganization !== null) {
      dispatch(fetchAllBotOfOrganization(currentOrganization.id, currentBotStatus));
      dispatch(setBreadcrumb([currentOrganization.name, 'bots']));
    }
  }, [currentOrganization, currentBotStatus]);

  const handleStatusSelectionChange = (selection: string): void => {
    setCurrentBotStatus(selection);
  };

  const handleOpenCreateBot = (): void => {
    setCreateDialogOpen(true);
  };

  const handleCancelCreateBotDialog = (): void => {
    setCreateDialogOpen(false);
  };

  const handleConfirmCreateDialog = (data: MutateBotWithoutOrganization): void => {
    createBot(data).then((res): void => {
      if (res) {
        setCreateDialogOpen(false);
      }
    });
  };

  return (
    <Fragment>
      <Typography variant="h2">{t('bot_title')}</Typography>
      <BotListFilters
        onSearchChange={searchHandler}
        onActionClick={handleOpenCreateBot}
        onSelectChange={handleStatusSelectionChange}
      />
      <Box sx={{ width: '100%', mt: 3 }}>
        {botsMemo.length > 0 || botsHaveSkeleton ? (
          <BotListTable bots={botsMemo} isFirstLoading={botsHaveSkeleton} />
        ) : (
          <AppFeedbackTitle text={t(querySearch.length === 0 ? 'ERROR.no_results' : 'ERROR.no_results_filter')} />
        )}
      </Box>
      <AppDialog
        closeButton
        open={createDialogOpen}
        title={t('bot_create')}
        content={<BotCreateForm onCancel={handleCancelCreateBotDialog} onSubmit={handleConfirmCreateDialog} />}
        onCancel={handleCancelCreateBotDialog}
        sx={{
          '.MuiDialog-paper': {
            width: '75vw',
            maxWidth: '80vw'
          }
        }}
      />
    </Fragment>
  );
};

export default memo(BotList);
