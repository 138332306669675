import { styled, TextField, TextFieldProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const AutoCompleteStyledFilledInput = styled(TextField)<TextFieldProps>(() => ({
  '&': {
    marginTop: 0,
    marginBottom: 0
  },
  '& .MuiFilledInput-input::placeholder': {
    fontStyle: 'italic',
    color: '#FFF',
    fontSize: '12px'
  },
  '& .MuiFilledInput-root': {
    paddingTop: '1px',
    paddingBottom: '1px'
  },
  '&.flatOnRight > .MuiFilledInput-root': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  '&.flatOnLeft > .MuiFilledInput-root': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}));

export type AutocompleteOption = {
  title: string;
  value: string;
};
