/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

declare module '@mui/material/styles' {
  interface CustomPalette {
    light: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    capitalized: true;
    noHover: true;
    secondary: true;
  }

  interface ButtonPropsColorOverrides {
    light: true;
  }
}

const OpenSansFont = "'Open Sans', sans-serif";
const PrimaryColor = '#95C11F';
const SeparatorColor = '#525252';

// eslint-disable-next-line react/display-name
const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref): JSX.Element => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
  }
);

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string): PaletteColor => augmentColor({ color: { main: mainColor } });

/**
 * Refer to
 * https://mui.com/material-ui/customization/theming/#custom-variables
 * https://bareynol.github.io/mui-theme-creator/
 *
 * for theme customization
 */
export const appTheme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 2100
    }
  },
  typography: {
    fontFamily: OpenSansFont,
    h2: {
      fontSize: '25px',
      fontWeight: 600,
      lineHeight: '34px',
      marginBottom: '26px'
    },
    body2: {
      fontSize: '12px',
      color: '#A5A5A5',
      lineHeight: '16px',
      fontWeight: 400
    },
    overline: {
      color: PrimaryColor
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: PrimaryColor
    },
    grey: {
      '200': '#979797',
      '300': '#292929',
      '500': '#000000de',
      '800': '#2F2F2F',
      '900': '#131313'
    },
    text: {
      primary: '#FFF',
      secondary: '#A5A5A5'
    },
    success: {
      main: PrimaryColor
    },
    error: {
      light: '#FF5C5C',
      main: '#AF2929',
      contrastText: '#FFF'
    },
    info: {
      main: '#47A7FF'
    },
    warning: {
      main: '#EFED91'
    },
    light: createColor('#FFF'),
    divider: SeparatorColor
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212',
          backgroundImage: 'none',
          boxShadow: 'none',
          borderBottom: `1px solid #2F2F2F`
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [
        {
          props: { variant: 'capitalized' },
          style: {
            textTransform: 'none',
            borderRadius: 0,
            fontSize: '14px',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        },
        {
          props: { variant: 'noHover' },
          style: {
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        },
        {
          props: { variant: 'secondary' },
          style: {
            textTransform: 'none',
            borderRadius: 0,
            textDecoration: 'underline',
            fontWeight: 600,
            color: '#FF5C5C',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            backgroundColor: '#8E2929'
          }
        }
      ]
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            backgroundColor: '#131313',
            backgroundImage: 'none',
            borderRadius: '16px',
            padding: '16px 32px'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: '#FFF'
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiListItem-root': {
            marginBottom: 14
          },
          '& .MuiListItemIcon-root': {
            marginRight: 8
          },
          '& .MuiListItemButton-root.Mui-selected': {
            backgroundColor: '#595959',
            '&:hover': {
              backgroundColor: '#595959'
            }
          },
          '& .MuiListItemText-root .MuiTypography-body1': {
            fontSize: 14,
            fontWeight: 600
          }
        },
        paper: {
          backgroundColor: '#1A1A1A',
          borderRight: 'none',
          backgroundImage: 'none'
        }
      }
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
        hiddenLabel: true
      },
      styleOverrides: {
        root: {
          '&.MuiFilledInput-root': {
            borderRadius: '8px',
            paddingLeft: '8px',
            paddingRight: '8px'
          },
          '& .MuiFilledInput-input': {
            paddingTop: '8px',
            paddingBottom: '8px'
          }
        }
      }
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    }
  }
});
