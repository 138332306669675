import { Entity } from '../../../domain/models/entity/entity';

export interface IEntityState {
  all: Entity[];
  isFirstLoading: boolean;
  current: Entity | null;
  isFirstLoadingCurrent: boolean;
}

export const initialEntityState: IEntityState = {
  all: [],
  isFirstLoading: true,
  current: null,
  isFirstLoadingCurrent: true
};
