import { FC, memo, Suspense } from 'react';
import { ChildrenProps } from '../../props/ChildrenProps';
import AppLoader from './AppLoader';

type IAppSuspenseProps = ChildrenProps;

const AppSuspense: FC<IAppSuspenseProps> = ({ children }): JSX.Element => {
  return <Suspense fallback={<AppLoader />}>{children}</Suspense>;
};

export default memo(AppSuspense);
