// For memoization look this https://react-redux.js.org/api/hooks#using-memoizing-selectors

import { createSelector } from '@reduxjs/toolkit';
import { AvailableRoute, IAppState, MessageApp } from '../model/appState';
import { RootState } from '../store';

export const selectAppConfig = (state: RootState): IAppState => state.app;

export const selectDrawerOpened = createSelector(selectAppConfig, (state: IAppState): boolean => state.drawerOpen);

export const selectAppMessage = createSelector(selectAppConfig, (state: IAppState): MessageApp => state.message);

export const selectBreadcrumb = createSelector(selectAppConfig, (state: IAppState): string[] => state.breadcrumb);

export const selectCurrentRoute = createSelector(
  selectAppConfig,
  (state: IAppState): AvailableRoute => state.currentRoute
);

export const selectIsAppLoading = createSelector(selectAppConfig, (state: IAppState): boolean => state.isLoading);
