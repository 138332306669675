import { createContext, useContext } from 'react';
import { IConfigProvider } from '../../models/config';
import { useConfigProvider } from './useConfigProvider';

const ConfigContext = createContext<IConfigProvider>({
  env: 'none',
  isLocal: true,
  url: {
    base: '',
    projectBase: ''
  },
  firebase: {
    projectId: '',
    appId: '',
    storageBucket: '',
    apiKey: '',
    authDomain: '',
    messagingSenderId: '',
    measurementId: ''
  }
});

export const useConfig = (): IConfigProvider => {
  return useContext(ConfigContext);
};

export function ConfigProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const config = useConfigProvider();
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}
