import { Typography, useTheme } from '@mui/material';
import { Namespace } from 'i18next';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusThemeColor } from '../../../../../domain/models/statusThemeColor';

type IAppStatusLabelProps = {
  text: string;
  status: StatusThemeColor;
  ns?: Namespace;
};

const AppStatusLabel: FC<IAppStatusLabelProps> = ({ text, status, ns }): JSX.Element => {
  const { t } = useTranslation(ns);
  const theme = useTheme();

  return (
    <Typography variant="body2" sx={{ color: theme.palette[status].main }}>
      {ns ? t<string>(text) : text}
    </Typography>
  );
};

export default memo(AppStatusLabel);
