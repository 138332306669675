import { Button } from '@mui/material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ADMINISTRATION_POLICIES } from '../../../../domain/models/policies';
import HasAnyPolicy from '../auth/HasAnyPolicy';
import SettingsIcon from '../icons/SettingsIcon';

const AppTopMenu: FC = (): JSX.Element => {
  const { t } = useTranslation(['TOP_NAV']);

  const goToLinkWithReplace = (link: string): void => {
    window.location.replace(link);
  };

  return (
    <div>
      <HasAnyPolicy policies={ADMINISTRATION_POLICIES}>
        <Button
          variant="capitalized"
          color="inherit"
          startIcon={<SettingsIcon />}
          onClick={(): void => goToLinkWithReplace('/administration')}>
          {t('administration')}
        </Button>
      </HasAnyPolicy>
    </div>
  );
};

export default memo(AppTopMenu);
