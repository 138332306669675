import { FC, memo } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './Routes';

const App: FC = (): JSX.Element => {
  const elements = useRoutes(routes);
  return <>{elements}</>;
};

export default memo(App);
