import { useAppDispatch, useAppSelector } from '../../../../data/store';
import {
  closeDrawer as closeDrawerAction,
  openDrawer as openDrawerAction
} from '../../../../data/store/actions/appActions';
import { selectDrawerOpened } from '../../../../data/store/selectors/appSelectors';

export type IUseDrawer = {
  open: boolean;
  toggleDrawer: () => void;
  openDrawer: () => void;
  closeDrawer: () => void;
};

export default function useDrawer(): IUseDrawer {
  const open = useAppSelector(selectDrawerOpened);
  const dispatch = useAppDispatch();

  const openDrawer = (): void => {
    dispatch(openDrawerAction());
  };

  const closeDrawer = (): void => {
    dispatch(closeDrawerAction());
  };

  const toggleDrawer = (): void => {
    if (open) {
      closeDrawer();
    } else {
      openDrawer();
    }
  };

  return {
    open,
    toggleDrawer,
    openDrawer,
    closeDrawer
  };
}
