import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../domain/models';
import { Role } from '../../../domain/models/role';
import { IAuthState, initialAuthState } from '../model/authState';
import { Organization } from './../../../domain/models/organization';

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setCurrentUser: (state: IAuthState, { payload }: PayloadAction<User>): void => {
      state.currentUser = payload;
    },
    setCurrentRole: (state: IAuthState, { payload }: PayloadAction<Role>): void => {
      state.currentRole = payload;
    },
    setCurrentUserAndRole: (state: IAuthState, { payload }: PayloadAction<{ user: User; role: Role }>): void => {
      state.currentRole = payload.role;
      state.currentUser = payload.user;
    },
    setCurrentOrganization: (state: IAuthState, { payload }: PayloadAction<Organization>): void => {
      state.currentOrganization = payload;
    }
  }
});

export default authSlice.reducer;
