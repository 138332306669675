import { Snackbar } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionProps } from '../../props/ActionProps';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref): JSX.Element {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type IAppSnackbarProps = {
  message: string | null;
  type?: 'system' | AlertColor;
} & Pick<ActionProps, 'onClose'>;

const AppSnackbar: FC<IAppSnackbarProps> = ({ message, type = 'system', onClose }): JSX.Element => {
  const { t } = useTranslation(['APP_MESSAGES']);
  return (
    <React.Fragment>
      {message && (
        <Snackbar
          open={message !== null}
          autoHideDuration={6000}
          onClose={onClose}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          {type !== 'system' ? (
            <Alert onClose={onClose} severity={type} sx={{ width: '100%' }}>
              {t<string>(message)}
            </Alert>
          ) : (
            <span>{t<string>(message)}</span>
          )}
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default memo(AppSnackbar);
