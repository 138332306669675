import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from '../../../domain/models';
import { initialProjectState, IProjectState } from '../model/projectState';

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const projectSlice = createSlice({
  name: 'projects',
  initialState: initialProjectState,
  reducers: {
    setAllProjects: (state: IProjectState, { payload }: PayloadAction<Project[]>): void => {
      state.all = payload;
    }
  }
});

export default projectSlice.reducer;
