import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './assets/i18n/en.json';
import it from './assets/i18n/it.json';

export const defaultNS = 'common';
export const resources = {
  en,
  it
};

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  defaultNS,
  returnNull: false,
  fallbackLng: 'en'
});

// Add formatters
i18n.services.formatter?.addCached('lowercase', (_lng: string | undefined, _options: unknown) => {
  return (val: string): string => val.toLocaleLowerCase();
});

i18n.services.formatter?.addCached('uppercase', (_lng: string | undefined, _options: unknown) => {
  return (val: string): string => val.toLocaleUpperCase();
});

export default i18n;
