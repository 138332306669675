import React, { FC, memo, useEffect } from 'react';
import { useConfig } from '../../../../domain/context/config/ConfigProvider';
import useHasPolicy from '../../../../domain/hooks/authorization/useHasPolicy';
import { Policy } from '../../../../domain/models/policies';
import { ChildrenProps } from '../../props/ChildrenProps';

type IRequireHasAnyPolicyProps = {
  policies: Policy[];
} & ChildrenProps;

const RequireHasAnyPolicy: FC<IRequireHasAnyPolicyProps> = ({ policies, children }): JSX.Element => {
  const { hasAnyPolicies: hasAny } = useHasPolicy();
  const { url } = useConfig();

  useEffect((): void => {
    if (!hasAny(policies)) {
      window.location.replace(url.base);
    }
  }, [policies]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default memo(RequireHasAnyPolicy);
