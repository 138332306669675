import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableRoute, IAppState, initialAppState, MessageApp, RemoteAppConfig } from '../model/appState';

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    setAppConfig: (state: IAppState, { payload }: PayloadAction<RemoteAppConfig>): void => {
      state.config = payload;
    },
    openDrawer: (state: IAppState): void => {
      state.drawerOpen = true;
    },
    closeDrawer: (state: IAppState): void => {
      state.drawerOpen = false;
    },
    setSystemMessage: (state: IAppState, { payload }: PayloadAction<string>): void => {
      state.message = {
        text: payload,
        type: 'system'
      };
    },
    setAppMessage: (state: IAppState, { payload }: PayloadAction<MessageApp>): void => {
      state.message = payload;
    },
    resetMessage: (state: IAppState): void => {
      state.message = {
        text: null,
        type: 'system'
      };
    },
    setBreadcrumb: (state: IAppState, { payload }: PayloadAction<string[]>): void => {
      state.breadcrumb = payload;
    },
    setCurrentRoute: (state: IAppState, { payload }: PayloadAction<AvailableRoute>): void => {
      state.currentRoute = payload;
    },
    setStartLoading: (state: IAppState): void => {
      state.isLoading = true;
    },
    setStopLoading: (state: IAppState): void => {
      state.isLoading = false;
    }
  }
});

export default appSlice.reducer;
