import { Box, Skeleton, styled } from '@mui/material';
import { DataGrid, DataGridProps, enUS, itIT } from '@mui/x-data-grid';
import { Localization } from '@mui/x-data-grid/utils/getGridLocalization';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const StyledDataGrid = styled(DataGrid)<DataGridProps>(({ theme }) => ({
  border: 0,
  '& .MuiDataGrid-columnSeparator svg': {
    color: 'transparent'
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `2px solid ${theme.palette.grey[200]}`
  },
  '& .MuiDataGrid-columnHeader': {
    '&:focus': {
      outline: 'none'
    }
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none'
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  }
}));

type IAppDataGridProps = DataGridProps & {
  displaySkeleton: boolean;
};

const AppDataGrid: FC<IAppDataGridProps> = (props): JSX.Element => {
  const { i18n } = useTranslation();

  const currentLocale = useMemo((): Localization => {
    return i18n.language === 'it-IT' ? itIT : enUS;
  }, [i18n.language]);

  return (
    <>
      {props.displaySkeleton ? (
        <Box sx={{ width: 1, mt: 5 }} aria-label="skeleton-app-datagrid">
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 4 }}>
            {props.checkboxSelection === false ? <></> : <Skeleton variant="rounded" width={30} height={30} />}
            <Skeleton variant="rounded" width="20%" height={30} sx={{ mr: 1 }} />
            <Skeleton variant="rounded" width="20%" height={30} sx={{ mr: 1 }} />
            <Skeleton variant="rounded" width="20%" height={30} sx={{ mr: 1 }} />
            <Skeleton variant="rounded" width="20%" height={30} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
            <Skeleton variant="rounded" height={40} sx={{ mb: 1 }} />
            <Skeleton variant="rounded" height={40} sx={{ mb: 1 }} />
            <Skeleton variant="rounded" height={40} sx={{ mb: 1 }} />
            <Skeleton variant="rounded" height={40} sx={{ mb: 1 }} />
            <Skeleton variant="rounded" height={40} sx={{ mb: 2 }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
            <Skeleton variant="rounded" height={30} width={100} />
          </Box>
        </Box>
      ) : (
        <StyledDataGrid
          aria-label="data-grid"
          disableColumnFilter
          disableColumnMenu
          localeText={currentLocale.components.MuiDataGrid.defaultProps.localeText}
          autoHeight
          pageSize={6}
          rowsPerPageOptions={[6]}
          checkboxSelection
          sortingOrder={['desc', 'asc']}
          {...props}
        />
      )}
    </>
  );
};

export default memo(AppDataGrid);
