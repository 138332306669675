import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionProps } from '../../props/ActionProps';
import AppDialog from './AppDialog';

type IAppAlertDialogProps = {
  title?: string;
  open: boolean;
  message: string | React.ReactNode;
} & Pick<ActionProps, 'onCancel' | 'onConfirm'>;

const AppAlertDialog: React.FC<IAppAlertDialogProps> = ({ title, open, message, onCancel, onConfirm }): JSX.Element => {
  const { t } = useTranslation(['ACTIONS']);

  return (
    <AppDialog
      open={open}
      title={title}
      content={message}
      onCancel={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      actions={
        <>
          <Button onClick={onCancel} variant="outlined" color="light" sx={{ mr: 2, px: 4, py: 0.5, fontWeight: 600 }}>
            {t('cancel')}
          </Button>
          <Button onClick={onConfirm} variant="contained" color="error" sx={{ px: 4, py: 0.5, fontWeight: 600 }}>
            {t('confirm')}
          </Button>
        </>
      }
    />
  );
};

export default React.memo(AppAlertDialog);
