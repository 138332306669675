import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Entity } from '../../../domain/models/entity/entity';
import { IEntityState, initialEntityState } from '../model/entityState';

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const entitySlice = createSlice({
  name: 'entities',
  initialState: initialEntityState,
  reducers: {
    setAllEntities: (state: IEntityState, { payload }: PayloadAction<Entity[]>): void => {
      state.all = payload;
    },
    setCurrentEntity: (state: IEntityState, { payload }: PayloadAction<Entity>): void => {
      state.current = state.current === null ? payload : state.current;
    },
    setCurrentEntityFromId: (state: IEntityState, { payload }: PayloadAction<string>): void => {
      state.current = state.all.find((b): boolean => b.id === payload) ?? null;
    },
    unsetCurrentEntity: (state: IEntityState): void => {
      state.current = null;
    },
    addEntity: (state: IEntityState, { payload }: PayloadAction<Entity>): void => {
      state.all.push(payload);
    },
    replaceEntity: (state: IEntityState, { payload }: PayloadAction<Entity>): void => {
      state.all = state.all.filter((s): boolean => s.id !== payload.id).concat(payload);
    },
    deleteEntity: (state: IEntityState, { payload }: PayloadAction<string>): void => {
      state.all = state.all.filter((s): boolean => s.id !== payload);
    },
    setEntityLoading: (state: IEntityState): void => {
      state.isFirstLoading = true;
    },
    unSetEntityLoading: (state: IEntityState): void => {
      state.isFirstLoading = false;
    },
    setCurrentEntityLoading: (state: IEntityState): void => {
      state.isFirstLoadingCurrent = true;
    },
    unSetCurrentEntityLoading: (state: IEntityState): void => {
      state.isFirstLoadingCurrent = false;
    }
  }
});

export default entitySlice.reducer;
