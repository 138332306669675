import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  MenuProps,
  styled,
  Typography
} from '@mui/material';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { useAppSelector } from '../../../../data/store';
import { selectCurrentUserWithRole } from '../../../../data/store/selectors/authSelectors';
import i18n from '../../../../i18n';
import useAssetUrl from '../../hooks/assets/useAssetUrl';
import useCustomNavigation from '../../hooks/navigation/useCustomNavigation';
import { OptionalChildrenProps } from '../../props/OptionlChildrenProps';
import AvatarIcon from '../icons/AvatarIcon';
import DrawerIcon from '../icons/DrawerIcon';
import AppMenu from './AppMenu';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AppTopUserMenuSyled = styled(AppMenu)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: '220px'
  },
  '& .MuiMenu-list': {
    '& .MuiMenuItem-root': {
      '&.Mui-disabled': {
        opacity: 1,
        '& .MuiListItemText-root': {
          '& .MuiTypography-root': {
            textDecoration: 'none',
            opacity: 0.38
          }
        }
      },
      '&.user-menu__caption': {
        display: 'block',
        cursor: 'auto',
        '&:hover': {
          background: 'transparent'
        },
        '& .MuiTypography-body2': {
          fontSize: 10,
          paddingBottom: 8,
          color: theme.palette.primary.main
        },
        '& .MuiTypography-h6': {
          fontSize: 12
        },
        '& .MuiTypography-subtitle2': {
          fontSize: 10
        }
      },
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          fontSize: 12,
          textDecoration: 'underline'
        }
      }
    }
  },
  '& .flag-icon': {
    height: 17,
    width: 17
  }
}));

type IAppTopUserMenuProps = OptionalChildrenProps;

const AppTopUserMenu: FC<IAppTopUserMenuProps> = ({ children }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [t] = useTranslation(['TOP_NAV', 'LANGUAGES']);
  const [, setLanguage] = useLocalStorage('language', 'en');
  const currentUser = useAppSelector(selectCurrentUserWithRole);

  const { getAsset } = useAssetUrl();
  const { navigate } = useCustomNavigation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const logout = (): void => {
    sessionStorage.clear();
    navigate('/');
  };

  const changeLanguage = (lng: 'en-UK' | 'it-IT'): void => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="noHover"
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        endIcon={<DrawerIcon />}>
        <AvatarIcon />
      </Button>
      <AppTopUserMenuSyled
        id="current-user-menu-appbar"
        sx={{ mt: '65px', mr: '95px' }}
        aria-controls="menu-user"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {currentUser && (
          <MenuItem className="user-menu__caption" disableRipple>
            <Typography variant="body2">{currentUser.role}</Typography>
            <Typography variant="h6">{`${currentUser.firstName} ${currentUser.lastName}`}</Typography>
            <Typography variant="subtitle2">{`${currentUser.email}`}</Typography>
          </MenuItem>
        )}
        <Divider />
        <MenuList dense>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <VpnKeyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('TOP_NAV:USER_MENU.reset_password')}</ListItemText>
          </MenuItem>
        </MenuList>
        <Divider />
        <MenuList dense>
          <MenuItem disabled>
            <ListItemIcon>
              <LanguageIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('TOP_NAV:USER_MENU.select_language')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={(): void => changeLanguage('en-UK')}>
            <ListItemIcon>
              <img src={getAsset('english_flag.png')} alt="english flag" className="flag-icon" />
            </ListItemIcon>
            <ListItemText>{t('LANGUAGES:en')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={(): void => changeLanguage('it-IT')}>
            <ListItemIcon>
              <img src={getAsset('italian_flag.png')} alt="italian flag" className="flag-icon" />
            </ListItemIcon>
            <ListItemText>{t('LANGUAGES:it')}</ListItemText>
          </MenuItem>
        </MenuList>
        <Divider />
        <MenuList dense>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <HelpOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('TOP_NAV:USER_MENU.info')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('TOP_NAV:USER_MENU.exit')}</ListItemText>
          </MenuItem>
        </MenuList>
        {children}
      </AppTopUserMenuSyled>
    </div>
  );
};

export default memo(AppTopUserMenu);
