import { PartialBotFlow } from '../../../domain/models/botbuilder/botflow';
import { BotBuilderPage } from '../../../domain/models/botbuilder/page';
import { StepFlow } from '../../../domain/models/botbuilder/stepflow';
import { BotBuilderIntent } from './../../../domain/models/botbuilder/intent';

export interface IBotBuilderState {
  bot: PartialBotFlow | null;
  startStep: StepFlow | null;
  steps: {
    [x: string]: StepFlow;
  };
  pages: BotBuilderPage[];
  intents: BotBuilderIntent[];
}

export const initialBotBuilderState: IBotBuilderState = {
  bot: null,
  startStep: null,
  steps: {},
  pages: [],
  intents: []
};
