import axios, { AxiosInstance } from 'axios';
import { User } from '../../models';
import { AuthRefreshDTO } from './dto/auth.dto';

/**
 * Singleton Client to handle Firebase calls
 */
export class FirebaseClient {
  private static instance: FirebaseClient;

  public get refreshToken(): string {
    return this.localAuth.refreshToken;
  }

  public get accessToken(): string {
    return this.localAuth.token;
  }

  private get localAuth(): User {
    const localAuth = sessionStorage.getItem('coreAuth');
    if (localAuth !== null) {
      return JSON.parse(window.atob(localAuth)) as User;
    }

    throw Error('No current auth');
  }

  private client: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_FIREBASE_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  public static getInstance(): FirebaseClient {
    if (!FirebaseClient.instance) {
      FirebaseClient.instance = new FirebaseClient();
    }

    return FirebaseClient.instance;
  }

  public refreshRemoteToken = async (): Promise<void> => {
    const { data } = await this.client.post<AuthRefreshDTO>(`token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`, {
      refreshToken: this.refreshToken,
      grant_type: 'refresh_token'
    });

    this.updateLocalAuth(data);
  };

  private updateLocalAuth = (authRefresh: AuthRefreshDTO): void => {
    const newDataAuth = {
      ...this.localAuth,
      token: authRefresh.access_token,
      refreshToken: authRefresh.refresh_token
    };
    sessionStorage.setItem('coreAuth', window.btoa(JSON.stringify(newDataAuth)));
  };
}
