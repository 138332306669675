import { useAppDispatch } from '../../../data/store';
import { setAppMessage } from '../../../data/store/actions/appActions';
import { createBotOfOrganization } from '../../../data/store/actions/botActions';
import { MutateBotWithoutOrganization } from '../../models/bot/mutatebot';

type IUseCreateBotUseCase = {
  createBot: (body: MutateBotWithoutOrganization) => Promise<boolean>;
};

export default function useCreateBotUseCase(): IUseCreateBotUseCase {
  const dispatch = useAppDispatch();

  const createBot = async (body: MutateBotWithoutOrganization): Promise<boolean> => {
    const res = await dispatch(createBotOfOrganization(body));
    if (res) {
      dispatch(
        setAppMessage({
          type: 'success',
          text: 'BOT.create_success'
        })
      );
    }
    return res;
  };

  return {
    createBot
  };
}
