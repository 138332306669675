import { Typography, useTheme } from '@mui/material';
import { Namespace } from 'i18next';
import { FC, memo } from 'react';
import { Translation } from 'react-i18next';

type IAppDataGridCellRegularProps = {
  text: string;
  ns?: Namespace;
  color?: string;
};

const AppDataGridCellRegular: FC<IAppDataGridCellRegularProps> = ({ text, ns, color }): JSX.Element => {
  const theme = useTheme();

  return ns ? (
    <Translation ns={ns}>
      {(t): JSX.Element => (
        <Typography sx={{ color: color ?? theme.palette.text.secondary, fontSize: '14px' }}>
          {t<string>(text)}
        </Typography>
      )}
    </Translation>
  ) : (
    <Typography sx={{ color: color ?? theme.palette.text.secondary, fontSize: '14px' }}>{text}</Typography>
  );
};

export default memo(AppDataGridCellRegular);
