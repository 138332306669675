import { Link as LinkMaterial, Typography } from '@mui/material';
import { FC, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

type IAppDataGridCellLinkProps = {
  text: string;
  link: string;
};

const AppDataGridCellLink: FC<IAppDataGridCellLinkProps> = ({ text, link }): JSX.Element => {
  const currentRoute = useLocation();

  return (
    <LinkMaterial to={link} component={Link} state={{ previousRoute: currentRoute }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{text}</Typography>
    </LinkMaterial>
  );
};

export default memo(AppDataGridCellLink);
