import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { FirebaseClient } from './firebaseClient';

/**
 * Singleton Client to handle API calls
 */
export class ApiClient {
  private static instance: ApiClient;

  private client: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });

  private firebaseClient = FirebaseClient.getInstance();

  get apiClient(): AxiosInstance {
    return this.client;
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }

    return ApiClient.instance;
  }

  constructor() {
    if (process.env.NODE_ENV !== 'test') {
      this.configureInterceptors();
    }
  }

  /**
   * Wrapper of GET to use Content-Type: application/json
   *
   * @param uri URL to fetch
   * @returns AxiosResponse of T
   */
  public get<T>(uri: string): Promise<AxiosResponse<T>> {
    return this.apiClient.get<T>(uri, { data: null });
  }

  private configureInterceptors(): void {
    this.client.interceptors.request.use(
      (config: AxiosRequestConfig): AxiosRequestConfig => {
        if (config.headers) {
          config.headers['Authorization'] = 'Bearer ' + this.firebaseClient.accessToken ?? '';
        }
        return config;
      },
      (error): Promise<AxiosResponse | AxiosError> => {
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      (res: AxiosResponse): AxiosResponse => {
        if (res.status === 204) {
          return {
            ...res,
            data: {
              data: []
            }
          };
        }

        return res;
      },
      async (err): Promise<AxiosResponse | AxiosError> => {
        const originalConfig = err.config as AxiosRequestConfig & { _retry: boolean };
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            await this.firebaseClient.refreshRemoteToken();

            if (originalConfig.headers) {
              originalConfig.headers['Authorization'] = 'Bearer ' + this.firebaseClient.accessToken ?? '';
            }

            return this.client(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }

        return Promise.reject(err);
      }
    );
  }
}
