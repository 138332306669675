import { IBotBuilderState } from './model/botBuilderState';
/* eslint-disable @typescript-eslint/ban-types */
import {
  AnyAction,
  combineReducers,
  configureStore,
  EnhancedStore,
  MiddlewareArray,
  PreloadedState,
  StoreEnhancer,
  ThunkMiddleware
} from '@reduxjs/toolkit';
import { IAppState } from './model/appState';
import { IAuthState } from './model/authState';
import { IBotState } from './model/botState';
import { IEntityState } from './model/entityState';
import { IintentState } from './model/intentState';
import { IProjectState } from './model/projectState';
import { appReducer, authReducer, botBuilderReducer, botReducer, entityReducer, projectReducer } from './reducers';
import intentReducer from './reducers/intentReducer';

export type IStore = {
  auth: IAuthState;
  projects: IProjectState;
  bots: IBotState;
  app: IAppState;
  entities: IEntityState;
  intents: IintentState;
  botBuilder: IBotBuilderState;
};

const combinedReducers = combineReducers({
  auth: authReducer,
  projects: projectReducer,
  bots: botReducer,
  app: appReducer,
  entities: entityReducer,
  intents: intentReducer,
  botBuilder: botBuilderReducer
});

export const setupStore = (
  preloadedState?: PreloadedState<IStore>
): EnhancedStore<
  IStore,
  AnyAction,
  MiddlewareArray<[ThunkMiddleware<IStore, AnyAction, undefined>]>,
  [StoreEnhancer<{}, {}>]
> => {
  return configureStore<
    IStore,
    AnyAction,
    MiddlewareArray<[ThunkMiddleware<IStore, AnyAction, undefined>]>,
    [StoreEnhancer<{}, {}>]
  >({
    reducer: combinedReducers,
    preloadedState,
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: false
      }),
    devTools:
      process.env.REACT_APP_LOCAL === 'true'
        ? {
            name: 'assistant'
          }
        : false
  });
};

const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
