import { AlertColor } from '@mui/material/Alert';

export interface RemoteAppConfig {
  assistant: boolean;
  devices: boolean;
  groups: boolean;
  organizations: boolean;
  projects: boolean;
  users: boolean;
}

export interface MessageApp {
  type: 'system' | AlertColor;
  text: string | null;
}

export type AvailableRoute = 'bots' | 'entities' | 'intents';

export interface IAppState {
  config: RemoteAppConfig;
  drawerOpen: boolean;
  message: MessageApp;
  breadcrumb: string[];
  currentRoute: AvailableRoute;
  isLoading: boolean;
}

export const initialAppState: IAppState = {
  config: {
    assistant: true,
    devices: true,
    groups: true,
    organizations: true,
    projects: true,
    users: true
  },
  drawerOpen: true,
  message: {
    type: 'system',
    text: null
  },
  breadcrumb: [],
  currentRoute: 'bots',
  isLoading: false
};
