import { EnvType, IConfigProvider } from '../../models/config';

export function useConfigProvider(): IConfigProvider {
  return {
    env: process.env.NODE_ENV as EnvType,
    isLocal: process.env.REACT_APP_LOCAL === 'true',
    url: {
      base: process.env.REACT_APP_BASE_URL || '',
      projectBase: process.env.REACT_APP_REMOTE_PATH || ''
    },
    firebase: {
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
      appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || ''
    }
  };
}
