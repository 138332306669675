import { AxiosError, isAxiosError } from 'axios';
import { ResponseErrorApiDTO } from '../../../domain/http/augmentya/dto/response.dto';
import { AppDispatch } from '../store';
import { setAppMessage } from './appActions';

/**
 * Common BL to handle error response
 * @param dispatch App dispatcher
 * @param e Error received
 */
export const handleLicenseErrorResponse = (dispatch: AppDispatch, e: unknown): void => {
  if (isAxiosError(e)) {
    let text = `API.generic`;
    const error = e as AxiosError<ResponseErrorApiDTO>;
    switch (error.response?.status) {
      case 403:
        text = `API.${error.response?.data.message === 'LC_BOT_0' ? 'product_not_enabled' : '403'}`;
        break;
      case 400:
        text = `API.${error.response?.data.message === 'LC_BOT_1' ? 'license_exausted' : '400'}`;
        break;
      default:
        text = `API.${error.response?.status || 'generic'}`;
    }

    dispatch(
      setAppMessage({
        text,
        type: 'error'
      })
    );
  } else {
    dispatch(
      setAppMessage({
        type: 'error',
        text: `API.generic`
      })
    );
  }
};

export const handleSimpleErrorReponse = (dispatch: AppDispatch, e: unknown): void => {
  if (isAxiosError(e)) {
    const error = e as AxiosError<ResponseErrorApiDTO>;
    dispatch(
      setAppMessage({
        text: `API.${error.response?.status?.toString() || 'generic'}`,
        type: 'error'
      })
    );
  } else {
    dispatch(
      setAppMessage({
        type: 'error',
        text: `API.generic`
      })
    );
  }
};
