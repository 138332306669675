import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './Root';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary<AppProps>(err: Error, info: React.ErrorInfo, props: AppProps): React.ReactElement {
    console.error(err, info, props);
    // Customize the root error boundary for your microfrontend here.
    return <></>;
  }
});

export const { bootstrap, mount, unmount } = lifecycles;
